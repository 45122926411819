import React, { useState, useContext, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Context } from "../context/contex";
import { formatNumber, formatLocalTime, shortenAddress, getQueryParam } from "../utils/helpers";
import axios from "axios";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "../pages/styles/style.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import AOS from "aos";
import "aos/dist/aos.css";

function Home() {
 const [newVideos, setNewVideos] = useState([]);
 const [popularVideos, setPopularVideos] = useState([]);
 const [popularCategories, setPopularCategories] = useState([]);

 const { address, token, chainId, isConnected, walletProvider, tokenBalance, apiURL, domain } =
  useContext(Context);

 const getNewVideos = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_new_watch_videos" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setNewVideos(data);
    console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getNewVideos();
 }, []);

 const getPopularVideos = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_all_popular_watch_videos" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setPopularVideos(data);
    console.log("Alive POPULAR:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getPopularVideos();
 }, []);

 const getPopularCategories = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_popular_watch_categories" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setPopularCategories(data);
    //  console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getPopularCategories();
 }, []);

 useEffect(() => {
  AOS.init({
   duration: 1000,
   once: false,
  });
 }, []);

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div
     id="main-content"
     style={{
      backgroundColor: "#1e1e1e",
     }}
    >
     <div
      className="thim-banner_home-1 ai-home-banner"
      style={{ backgroundImage: "url(assets/images/home.jpg)" }}
     >
      <div className="overlay-areaa" />
      <div className="container-fluid">
       <div className="bp-element bp-element-st-list-videos vblog-layout-1">
        <div className="wrap-element">
         <div className="feature-item"></div>
        </div>
       </div>
      </div>
     </div>
     {/*POPULAR-*/}

     <div className="content container-fluid mt-5 p-sm-0 p-md-4 p-lg-5">
      <div className="row d-flex align-items-center" data-aos="fade-right">
       <div className="col-md-6">
        <img src="assets/images/18coin.jpg" width="100%" />
       </div>
       <div className="col-md-6">
        <h1 className="mt-3" style={{ fontSize: "35px", fontWeight: 700 }}>
         ABOUT
        </h1>
        <div className="about my-3">
         <p>
          {" "}
          Τhe <b>Ai18plus</b> is entering the market with a fully developed project, demonstrating
          its preparedness to enhance value within the adult entertainment sector right from the
          outset.
         </p>
        </div>
        <div className="about my-3">
         <p>
          For the first time, we are bringing web 3.0 technologies to the largest revenue-generating
          digital industry, and we are transforming the level of audiovisual experience by
          incorporating services that will “disrupt” the more than 3 billion monthly users -
          visitors of adult sites. The innovations we provide are so numerous and so ...useful that
          it is certain the ai18plus.com platform, which is fully connected to Coin 18+, will gain a
          devoted audience, as you will understand from the detailed description.
         </p>
        </div>
        <div className="about my-3">
         We are creating the first adult digital community where users – viewers and content
         creators – EARN REWARDS for the interactions they generate, in an environment they already
         tend to visit almost daily.
        </div>
       </div>
      </div>
     </div>

     <section
      className="py-5 px-3 overlay-section"
      style={{
       backgroundImage: 'url("assets/images/nft-bg4.jpeg")',
       backgroundRepeat: "no-repeat",
       backgroundAttachment: "fixed",
       backgroundSize: "cover",
      }}
     >
      <div className="container">
       <div className="content row my-5">
        <div className="col-12 text-center my-5">
         <h1 className="display-4 text-white" data-aos="fade-right">
          WHY CHOOSE OUR TOKEN?
         </h1>
        </div>
        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           INNOVATION & FIRSTMOVER ADVANTAGE
          </h4>
          First Web3 adult entertainment platform leveraging blockchain and AI technology.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           ALL-IN-ONE PLATFORM
          </h4>
          Combines 3 different technologies in 1 platform: 1. AI model creation with prompts and
          tags. 2. Face swap. 3. Background removal.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           USER-GENERATED CONTENT
          </h4>
          Allows creation of AIgenerated models and customizable content without real-person ethical
          issues, catering to privacy concerns.
         </div>
        </div>
       </div>
       <div className="content row my-5">
        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           NFTid INTEGRATION
          </h4>
          Unique AI models are minted as NFTs, allowing for ownership and trade.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           REDUCED LEGAL RISKS
          </h4>
          AI models mitigate legal issues related to consent and exploitation.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           ADVANCED TECHNOLOGY
          </h4>
          Utilizes Binance Smart Chain for security, scalability and smart contracts for
          transparency and fraud reduction.
         </div>
        </div>
       </div>
       <div className="content row my-5">
        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           DECENTRALIZATION
          </h4>
          Combines Web2 and Web3 elements, ensuring data control and a transparent, secure digital
          content economy.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           PRIVACY & ANONYMITY
          </h4>
          Ensures user anonymity and privacy through blockchain technology.
         </div>
        </div>

        <div data-aos="fade-left" className="col-md-4 text-center">
         <div className="advantage-box">
          <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
           MONETIZATION OPPORTUNITIES
          </h4>
          Through subscriptions, paid videos and photos, NFTid lending, viewing ads, Business
          Revenue Tokens (BRTs) and content ratings.
         </div>
        </div>
       </div>
      </div>
     </section>

     <div className="content container-fluid mt-5">
      <div className="row d-flex align-items-center" data-aos="fade-left">
       <div className="col-md-2"></div>
       <div className="col-md-8">
        <img src={`${domain}assets/images/tokenomics.png`} width="100%" />
       </div>
       <div className="col-md-2"></div>
      </div>
     </div>

     <div className="container-fluid my-5 p-sm-0 p-lg-5 p-md-5">
      <div className="row">
       <div className="col-12 text-center my-5">
        <h1 className="display-4" data-aos="fade-right">
         OUR PLATFORMS
        </h1>
       </div>
       <div className="col-md-6" data-aos="fade-right">
        <div className="card card-custom">
         <div className="card-header">
          <img src="assets/images/watch2earn.jpg" alt="Logo" className="img-fluid" />
         </div>
         <div className="card-body">
          <h5 className="card-title">WATCH TO EARN</h5>

          <p className="card-text">
           On WATCH TO EARN, creators of erotic content have the ability to upload and share their
           personal moments with the community, whether it's their real faces or using our
           platform's AI services to swap their faces with an Ai face. On WATCH TO EARN, creators
           earn tokens every time their video gets a unique view. The viewer also earns tokens for
           watching the video. So it's a win-win for both creators and viewers.
          </p>
          <a href="/watch" className="btn btn-poocoin">
           WATCH NOW
          </a>
         </div>
        </div>
       </div>
       <div className="col-md-6" data-aos="fade-up">
        <div className="card card-custom">
         <div className="card-header">
          <img
           src={`${domain}uploads/generated/_66a7bd4fb02c1image.png`}
           alt="Logo"
           className="img-fluid"
          />
         </div>
         <div className="card-body">
          <h5 className="card-title">JUST FANS</h5>

          <p className="card-text">
           On “Just Fans” models can showcase their bodies in erotic scenes, replacing their faces
           with AI-generated faces. In this case, there is NO KYC (Know Your Customer) required
           since the model's face is not shown, thus eliminating any identification issues
          </p>
          <a href="/become-creator" className="btn btn-poocoin">
           JOIN
          </a>
         </div>
        </div>
       </div>
      </div>
     </div>

     <div className="container-fluid my-5 p-sm-0 p-lg-5 p-md-5">
      <div className="row">
       <div className="col-12 text-center my-5">
        <h1 className="display-4" data-aos="fade-right">
         OUR SERVICES
        </h1>
       </div>

       <div className="col-md-4" data-aos="fade-left">
        <div className="card card-custom">
         <div className="card-header">
          <img src="assets/images/aimodel2.png" alt="Logo" className="img-fluid" />
         </div>
         <div className="card-body">
          <h5 className="card-title">CREATE AI MODELS & SWAP FACES</h5>

          <p className="card-text">
           Users can create their own AI model. They can use video and photo rights from the
           ai18plus.com library, replace faces with their own AI model, and open a page featuring
           real bodies and action but with an AI model. Here too, no KYC is required
          </p>
          <div className="row">
           <div className="col-12">
            <a href="/porn-face" className="btn btn-poocoin">
             CREATE A MODEL
            </a>
           </div>
          </div>
         </div>
        </div>
       </div>
       <div className="col-md-4" data-aos="fade-right">
        <div className="card card-custom">
         <div className="card-header">
          <img src="assets/images/aimodel.png" alt="Logo" className="img-fluid" />
         </div>
         <div className="card-body">
          <h5 className="card-title">FACE SWAP</h5>

          <p className="card-text">
           Our innovative face swap service allows models to create captivating erotic scenes by
           replacing their faces with custom AI-generated faces. This approach maintains privacy as
           the model’s real face is never shown, eliminating the need for KYC (Know Your Customer)
           and addressing any identification concerns.
          </p>
          <div className="row">
           <div className="col-12">
            <a href="/face-swap" className="btn btn-poocoin">
             SWAP FACE
            </a>
           </div>
          </div>
         </div>
        </div>
       </div>

       <div className="col-md-4" data-aos="fade-down">
        <div className="card card-custom">
         <div className="card-header">
          <img src="assets/images/nft-license.jpg" alt="Logo" className="img-fluid" />
         </div>
         <div className="card-body">
          <h5 className="card-title">NFT ID</h5>

          <p className="card-text">
           A major innovation on our platform is what we call "NFT ID." Every model created by the
           platform's AI and deemed "worthy" by a user for a "Just Fans" page must be linked to
           their wallet. It will then be connected and recorded on the blockchain as an image
           produced by the platform's AI section, storing the face as a UNIQUE product of artificial
           intelligence processing. THEREFORE, NOT A REAL PERSON, and will receive an NFT ID, making
           the first recorded "creation" of a digital model by AI that gains an IDENTITY.
          </p>
          <a href="/porn-face" className="btn btn-poocoin">
           TRY NOW
          </a>
         </div>
        </div>
       </div>
      </div>
     </div>

     <div className="container-fluid roadmap-section py-5">
      <h1 className="my-5 text-center display-4" data-aos="fade-right">
       OUR ROADMAP
      </h1>
      <div className="row roadmap-timeline">
       {/* Phase 1 */}
       <div data-aos="fade-right" className="col-md-6 roadmap-phase mb-5">
        <div className="roadmap-icon bg-primary text-white">
         <i className="fa fa-rocket"></i>
        </div>
        <div className="roadmap-content">
         <h3 className="roadmap-phase-title">Phase 1</h3>
         <ul className="list-unstyled">
          <li>1. Business plan for Ai18plus</li>
          <li>2. Platform layout and planning</li>
          <li>3. Private presale (ambassadors)</li>
          <li>4. (Watch to earn Video and VR) development</li>
          <li>5. (Your porn face) development</li>
          <li>6. (Face swap) photo and video development</li>
          <li>7. (Market place) development</li>
          <li>8. (Fans) real and Ai for content creators, development</li>
          <li>9. (NFT nude collectors) development</li>
         </ul>
        </div>
       </div>

       {/* Phase 2 */}
       <div data-aos="fade-left" className="col-md-6 roadmap-phase mb-5">
        <div className="roadmap-icon bg-secondary text-white">
         <i className="fa fa-line-chart"></i>
        </div>
        <div className="roadmap-content">
         <h3 className="roadmap-phase-title">Phase 2</h3>
         <ul className="list-unstyled">
          <li>1. Strategic investors partnerships (presale round 2)</li>
          <li>2. Marketing plan for prelaunch</li>
          <li>3. (NFT ID) development</li>
          <li>4. Partnership deals for launch</li>
          <li>5. Cex negotiations</li>
          <li>6. Final touch ups and testing of all our products (beta)</li>
         </ul>
        </div>
       </div>

       {/* Phase 3 */}
       <div data-aos="fade-up" className="col-md-6 roadmap-phase mb-5">
        <div className="roadmap-icon bg-success text-white">
         <i className="fa fa-users"></i>
        </div>
        <div className="roadmap-content">
         <h3 className="roadmap-phase-title">Phase 3</h3>
         <ul className="list-unstyled">
          <li>1. Prelaunch marketing on all social platforms</li>
          <li>2. Social media creation and building audiences</li>
          <li>3. Platform goes live</li>
          <li>4. Marketing</li>
          <li>5. Launch ai18plus on Dex</li>
          <li>6. AMA on well known communities</li>
          <li>7. 50000 Active users</li>
          <li>8. 5000 holders</li>
         </ul>
        </div>
       </div>

       {/* Phase 4 */}
       <div data-aos="fade-right" className="col-md-6 roadmap-phase mb-5">
        <div className="roadmap-icon bg-info text-white">
         <i className="fa fa-bullhorn"></i>
        </div>
        <div className="roadmap-content">
         <h3 className="roadmap-phase-title">Phase 4</h3>
         <ul className="list-unstyled">
          <li>1. Press release</li>
          <li>2. Billboards around the world</li>
          <li>3. Cex listings</li>
          <li>4. 100000 active users</li>
          <li>5. 15000 holders</li>
          <li>6. New developments (Ai)</li>
          <li>7. Marketing</li>
         </ul>
        </div>
       </div>
      </div>
     </div>

     <section
      className="py-5 px-3 overlay-section"
      style={{
       backgroundImage: 'url("assets/images/nft-bg4.jpeg")',
       backgroundRepeat: "no-repeat",
       backgroundAttachment: "fixed",
       backgroundSize: "cover",
      }}
     >
      <div className="container">
       <div className="content row my-5">
        <div className="col-12 text-center my-5">
         <h1 className="display-4 text-white" data-aos="fade-right">
          OUR TEAM
         </h1>
        </div>
        <div data-aos="fade-left" className="col-md-4 text-center">
         40 specialized professors who form the core of the “crypto academy” and over 150 students
         who hold an internationally recognized degree as “cryptospecialists” make up the main
         project team.
        </div>
        <div data-aos="fade-right" className="col-md-4 text-center">
         Incredible developers who ensure the project's progress, award- winning companies, and
         individuals from the marketing and communication sectors.
        </div>
        <div data-aos="fade-right" className="col-md-4 text-center">
         An exclusive team composed of seasoned professionals from various sectors of the sex
         industry who bring in extensive experience and specialized knowledge, offering unparalleled
         insights and skills.
        </div>
       </div>
      </div>
     </section>
    </div>
    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default Home;
