import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import axios from "axios";
import { showAlert } from "../../utils/helpers";
import "../styles/post-video.css";
const now = Math.floor(Date.now() / 1000);

function PostVideo() {
 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  user,
  setLoading,
 } = useContext(Context);
 const [categories, setCategories] = useState([]);
 const [category, setCategory] = useState("");
 const [title, setTitle] = useState("");
 const [isVR, setIsVR] = useState("no");
 const [isAI, setIsAI] = useState("no");
 const [isPrivate, setIsPrivate] = useState("no");

 const [description, setDescription] = useState("");
 const [tags, setTags] = useState("");
 const [coverPhoto, setCoverPhoto] = useState(null);
 const [uploadVideo, setUploadVideo] = useState(null);
 const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
 const [uploadVideoPreview, setUploadVideoPreview] = useState(null);
 const [videoDurationValid, setVideoDurationValid] = useState(true);

 const [uploadProgress, setUploadProgress] = useState(0);
 const videoRef = useRef(null);
 const canvasRef = useRef(null);
 const [frames, setFrames] = useState([]);
 const [frame, setFrame] = useState(null);

 const [selectedFrame, setSelectedFrame] = useState("");

 const extractFrames = () => {
  const video = videoRef.current;
  if (!video) return;

  const duration = video.duration;
  if (!duration || isNaN(duration)) {
   console.error("Failed to get video duration");
   setLoading(false);
   return;
  }

  setLoading(true);
  const interval = duration / 10; // Adjust for more or fewer frames
  const framesArray = [];

  for (let i = 0; i <= 10; i++) {
   setTimeout(() => {
    const currentTime = i * interval;
    if (isFinite(currentTime)) {
     video.currentTime = currentTime;
     video.onseeked = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      framesArray.push(canvas.toDataURL());
      if (framesArray.length === 11) {
       setFrames(framesArray);
       setFrame(null);
       setSelectedFrame("");
       setLoading(false);
      }
     };
    } else {
     console.error("Calculated currentTime is not finite", currentTime);
    }
   }, i * 200); // Adjust timeout to ensure the video has time to seek
  }
 };

 useEffect(() => {
  const video = videoRef.current;
  if (!video) return;

  const handleLoadedMetadata = () => {
   extractFrames();
  };

  video.addEventListener("loadedmetadata", handleLoadedMetadata);

  return () => {
   video.removeEventListener("loadedmetadata", handleLoadedMetadata);
  };
 }, [uploadVideoPreview]);

 const handleFrame = async (frame, index) => {
  setSelectedFrame(index);
  setCoverPhoto(null);
  setCoverPhotoPreview(null);
  setFrame(frame);
 };

 const base64ToBlob = (base64, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
   const slice = byteCharacters.slice(offset, offset + sliceSize);
   const byteNumbers = new Array(slice.length);
   for (let i = 0; i < slice.length; i++) {
    byteNumbers[i] = slice.charCodeAt(i);
   }
   const byteArray = new Uint8Array(byteNumbers);
   byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
 };

 const getCategories = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_categories" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setCategories(data);
    //  console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getCategories();
 }, []);

 const handleCoverPhotoChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   setFrame(null);
   setSelectedFrame("");
   setCoverPhoto(file);
   setCoverPhotoPreview(URL.createObjectURL(file));
  }
 };

 const handleVideoChange = (e) => {
  const file = e.target.files[0];
  if (file && file.type.startsWith("video/")) {
   const videoElement = document.createElement("video");
   videoElement.preload = "metadata";
   videoElement.onloadedmetadata = function () {
    window.URL.revokeObjectURL(videoElement.src);
    const duration = videoElement.duration;
    if (duration > 600) {
     setVideoDurationValid(false);
     setUploadVideo(null);
     setUploadVideoPreview(null);
     setFrames([]);
     return showAlert({
      title: "Error!",
      text: "Video is longer than 10 minutes.",
      icon: "error",
      confirmButtonText: "Ok",
     });
    } else {
     setVideoDurationValid(true);
     setUploadVideo(file);
     setUploadVideoPreview(URL.createObjectURL(file));
     //  extractFrames();
    }
   };
   videoElement.src = URL.createObjectURL(file);
  }
 };

 const handleSubmit = async (e) => {
  e.preventDefault();
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (user.verified !== "yes") {
   return showAlert({
    title: "Error!",
    text: "You can only make posts after you pass KYC verification.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (!title || !description || uploadVideo == null || !tags) {
   return showAlert({
    title: "Error!",
    text: "Please fill all required fields.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }
  if (coverPhoto == null && frame == null) {
   return showAlert({
    title: "Error!",
    text: "Please upload a cover image or select one from the video frames.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }
  if (!videoDurationValid) {
   return showAlert({
    title: "Error!",
    text: "Please select a video that is less than 10 minutes.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }
  setLoading(true);
  const formData = new FormData();
  formData.append("category", category);
  formData.append("title", title);
  formData.append("isVR", isVR);
  formData.append("isAI", "no");

  formData.append("description", description);
  formData.append(
   "coverPhoto",
   coverPhoto === null ? base64ToBlob(frame.split(",")[1], "image/png") : coverPhoto,
   "frame.png"
  );
  formData.append("uploadVideo", uploadVideo);
  formData.append("tags", tags);
  formData.append("post_date", now.toString());
  formData.append("poster", address);

  formData.append("action", "post_watch_video");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
     setUploadProgress(percentCompleted);
    },
   });
   console.log("Form", response);
   if (response.data.success) {
    showAlert({
     title: "Success!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Ok",
    });
    setTimeout(() => {
     window.location.href = isVR === "yes" ? "/watch-vr" : `/single-video?vid=${response.data.id}`;
    }, 2000);

    //  console.log("Form submitted successfully");
    // Handle success (e.g., show a success message, reset the form)
   } else {
    showAlert({
     title: "ERROR!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   // alert(`Error: ${error.message}`);
   // Handle network error
  }
  setLoading(false);
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: "url(assets/images/bg-page-title.jpg)",
        }}
       >
        <div className="overlay-top-header" />
        <div className="content container" style={{ paddingBottom: "30px" }}>
         <h1>SUBMIT A VIDEO</h1>
         <div className="description">
          Use the form below to upload your videos. You get paid everytime others watch your videos.
         </div>
        </div>
       </div>
      </div>
      <div className="site-content layout-1">
       <div className="container">
        <div className="form-container">
         <h2 className="text-center mb-4">Submit Your Video</h2>
         {!isConnected ? (
          <div className="alert-warning p-3 my-3 rounded">
           <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Please connect a wallet
           to be able to make a post.
          </div>
         ) : (
          <form onSubmit={handleSubmit}>
           {user.verified !== "yes" && (
            <div className="description">
             <div className="alert-danger p-3 my-3 rounded">
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> You must pass KYC to
              be able to post a video. Please <a href={`edit-profile`}>go to your profile</a> and
              apply.
             </div>
            </div>
           )}
           <div className="row">
            <div className="col-md-6">
             <div className="form-group">
              <label htmlFor="videoCategory">Video Category</label>
              <select onChange={(e) => setCategory(e.target.value)} required>
               <option>Select a category</option>
               {categories.length > 0 &&
                categories.map((cat, i) => (
                 <option key={`cat${i}`} value={cat.category}>
                  {cat.category}
                 </option>
                ))}
              </select>
             </div>
            </div>
            <div className="col-md-6">
             <div className="form-group">
              <label htmlFor="videoTitle">Video Title</label>
              <input
               type="text"
               className="form-control"
               id="videoTitle"
               placeholder="Enter video title"
               onChange={(e) => setTitle(e.target.value)}
               required
              />
             </div>
            </div>

            <div className="col-md-12">
             <div className="form-group">
              <label htmlFor="videoDescription">Video Description</label>
              <textarea
               className="form-control"
               id="videoDescription"
               style={{ height: "120px" }}
               placeholder="Enter video description"
               required
               onChange={(e) => setDescription(e.target.value)}
              />
             </div>
            </div>

            <div className="col-md-12 my-5">
             <div className="row">
              <div className="col-md-6">
               <label htmlFor="real" className="rcontainer">
                This is a regular video
                <input
                 id="real"
                 value="no"
                 type="radio"
                 name="creator"
                 onChange={(e) => setIsVR(e.target.value)}
                 checked={isVR === "no"}
                 required
                />
                <span className="rcheckmark"></span>
               </label>
              </div>

              <div className="col-md-6">
               <label htmlFor="ai" className="rcontainer">
                This is a VR 360 compatible video
                <input
                 id="ai"
                 type="radio"
                 name="creator"
                 value="yes"
                 onChange={(e) => setIsVR(e.target.value)}
                 checked={isVR === "yes"}
                />
                <span className="rcheckmark"></span>
               </label>
              </div>
             </div>
            </div>

            <div className="col-md-6 text-center">
             <div className="form-group">
              <label htmlFor="uploadVideo">
               <video
                ref={videoRef}
                src={uploadVideoPreview}
                height={200}
                controls
                alt="video placeholder"
               />
              </label>
              <div className="custom-file">
               <input
                type="file"
                className="custom-file-input"
                id="uploadVideo"
                accept="video/mp4"
                onChange={handleVideoChange}
                required
               />
               <label className="custom-file-label" htmlFor="uploadVideo">
                Choose video file
               </label>
              </div>
             </div>
            </div>
            <div className="col-md-6 text-center">
             <div className="form-group">
              <label htmlFor="coverPhoto">
               <img
                src={frame || coverPhotoPreview || "./assets/images/ph-image.png"}
                height={200}
                alt="cover placeholder"
               />
              </label>
              <div className="custom-file">
               <input
                type="file"
                className="custom-file-input"
                id="coverPhoto"
                accept="image/*"
                onChange={handleCoverPhotoChange}
               />
               <label className="custom-file-label" htmlFor="coverPhoto">
                Choose cover photo
               </label>
              </div>
             </div>
            </div>

            <div className="col-md-12 my-3">
             <div className=" text-center my-5">
              {frames.length > 0 && (
               <h3 className="mb-3">Click any frame in the video to use as cover photo </h3>
              )}
              <canvas ref={canvasRef} width="320" height="180" style={{ display: "none" }} />
              <div
               style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
               }}
              >
               {frames.length > 0 &&
                frames.map((frame, index) => (
                 <img
                  key={index}
                  src={frame}
                  alt={`Frame ${index}`}
                  onClick={() => handleFrame(frame, index)}
                  style={{
                   cursor: "pointer",
                   width: "100px",
                   height: "auto",
                  }}
                  className={`frame-image ${selectedFrame === index ? "selected" : ""}`}
                 />
                ))}
              </div>
             </div>
            </div>

            <div className="col-md-12">
             <div className="form-group">
              <label htmlFor="videoTags">Tags</label>
              <input
               type="text"
               className="form-control"
               id="videoTags"
               placeholder="Enter tags separated by commas"
               onChange={(e) => setTags(e.target.value)}
               required
              />
             </div>
            </div>
            {uploadProgress > 0 && (
             <div className="col-md-12 my-4">
              <div className="progress">
               <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadProgress}%` }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
               >
                {uploadProgress}%
               </div>
              </div>
             </div>
            )}

            <div className="col-md-12">
             <button type="submit" className="btn btn-custom btn-block">
              Submit
             </button>
            </div>
           </div>
          </form>
         )}
        </div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default PostVideo;
