import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 getQueryParam,
 getPercentage,
} from "../../utils/helpers";
import axios from "axios";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function FanVideo() {
 const [video, setVideo] = useState([]);
 const [theVideo, setTheVideo] = useState("");
 const [duration, setDuration] = useState("");
 const [userFans, setUserFans] = useState([]);
 const [fan, setFan] = useState({});

 const [watched, setWatched] = useState(false);

 const [userVideos, setUserVideos] = useState([]);
 const [relatedVideos, setRelatedVideos] = useState([]);

 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  settings,
  domain,
 } = useContext(Context);

 const getVideo = async () => {
  let vid;
  try {
   vid = getQueryParam("vid");
   if (vid == null) return;

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_single_video", vid: vid }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setVideo(data[0]);
    setTheVideo(data[0].video.toString());

    console.log("Fetched Video URL:", data[0].video);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getVideo();
 }, []);

 const getUserVideos = async (wallet) => {
  if (!wallet || wallet == undefined) return;
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "get_user_videos",
     wallet: wallet,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserVideos(data);
    //  console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getUserVideos(video.poster);
 }, [video]);

 const payReward = async (connected, wallet, watched, video) => {
  if (!connected || !wallet) return;

  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     user: wallet,
     vid: video.id,
     action: "pay_watch_reward",
     poster: video.poster,
     is_private: "yes",
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   console.log("D", data);
   await getVideo();
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 const getUserFans = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_user_fans",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserFans(data);
    console.log("All fans:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 const searchArray = (fans, wallet) => {
  if (!fans) return false;
  const user = fans.find((user) => user.wallet === wallet && user.is_active === 1);
  if (!user) {
   setFan({});
   return undefined;
  }
  return user;
 };

 useEffect(() => {
  if (!video) return;
  getUserFans(video.poster);
 }, [video]);

 useEffect(() => {
  if (userFans.length === 0 || !isConnected || address === video?.poster) {
   setFan({});
   return;
  }
  const f = searchArray(userFans, address);
  if (f === undefined || !f) {
   setFan({});
  } else {
   setFan(f);
  }
  console.log("FAN", searchArray(userFans, address));
 }, [userFans, isConnected, address]);

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: "url(assets/images/bg-page-title.jpg)",
        }}
       >
        <div className="overlay-top-header" />
        <div className="content container pt-5" style={{ marginBottom: "150px" }}>
         <h1 className="mb-3 text-capitalize">{video.title}</h1>
         {Object.keys(fan).length === 0 && video.poster !== address && (
          <div className="alert-danger rounded p-3">
           <i className="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i> You must
           have an active subscription to watch this video.{" "}
           <a
            href={`profile?user=${!video.username ? video.poster : video.username}`}
            style={{ color: "red" }}
            target="_blank"
           >
            Go to {!video.username ? shortenAddress(video.poster) : video.username}
            's profile{" "}
           </a>{" "}
           to subscribe and then reload this page to watch.
          </div>
         )}
        </div>
       </div>
      </div>

      <div className="site-content layout-1">
       <div className="container">
        <div className="row">
         <main className="site-main col-12">
          <div className="wrap-main-content">
           <div className="bl-video-detail">
            {theVideo && (Object.keys(fan).length > 0 || video.poster === address) && (
             <div className="player-video">
              <div className="bg-video">
               <div className="video-container">
                <video
                 controls
                 width="100%"
                 height="660px"
                 onEnded={() => payReward(isConnected, address, watched, video)}
                >
                 <source src={theVideo} type="video/mp4" />
                 Your browser does not support the video tag.
                </video>
               </div>
              </div>
             </div>
            )}
            <div
             style={{ display: "none" }}
             id="alert-success"
             className="col-md-12 alert-success p-3 my-4 rounded"
            ></div>
            <div
             style={{ display: "none" }}
             id="alert-error"
             className="col-md-12 alert-danger p-3 my-4 rounded"
            ></div>
            <div className="detail-video">
             <div className="info-detail sticky-sidebar">
              <div className="inner-info">
               <div className="media-video">
                <div className="pic-video">
                 <img
                  src={domain + video.cover_photo}
                  alt="IMG"
                  style={{
                   height: "400px",
                   objectFit: "cover",
                  }}
                 />
                </div>

                <div className="more-info">
                 <span className="item-info">
                  <i className="ion ion-eye" />
                  {Math.floor(formatNumber(video.views))} Views
                 </span>
                </div>
               </div>
               <div className="info-video">
                <div className="item-info">
                 <span className="name-item"> Posted by: </span>
                 <span className="value-item">
                  {" "}
                  <a
                   className="text-danger"
                   href={`profile?user=${!video.username ? video.poster : video.username}`}
                  >
                   {!video.username ? shortenAddress(video.poster) : video.username}
                  </a>{" "}
                 </span>
                </div>
                <div className="item-info">
                 <span className="name-item"> Posted on: </span>
                 <span className="value-item"> {formatLocalTime(video.post_date)} </span>
                </div>
                <div className="item-info">
                 <span className="name-item"> Runtime: </span>
                 <span className="value-item"> {duration} min </span>
                </div>
                <div className="item-info">
                 <span className="name-item"> Type: </span>
                 <span className="value-item">
                  {" "}
                  {`${video.is_vr == "yes" ? "VR" : "Standard"} video`}{" "}
                 </span>
                </div>
               </div>
              </div>
             </div>
             <div className="content-detail">
              <div className="field-detail">
               <h3 className="title-field" style={{ color: "#111" }}>
                Video Description
               </h3>
               <div className="content-field">
                <div>{video.description}</div>
               </div>
              </div>
              <div className="field-detail">
               <h3 className="title-field" style={{ color: "#111" }}>
                Other Videos by{" "}
                <a
                 href={`profile?user=${!video.username ? video.poster : video.username}`}
                 style={{ color: "red" }}
                >
                 {!video.username ? shortenAddress(video.poster) : video.username}
                </a>
               </h3>
               <div className="content-field">
                <Swiper
                 navigation={true}
                 modules={[Pagination, Autoplay, Navigation]}
                 autoplay
                 //effect="fade"
                 pagination={{ clickable: true }}
                 // slidesPerView={3}
                 loop={true}
                 breakpoints={{
                  640: {
                   slidesPerView: 1,
                   spaceBetween: 20,
                  },
                  768: {
                   slidesPerView: 2,
                   spaceBetween: 30,
                  },
                  1024: {
                   slidesPerView: 2,
                   spaceBetween: 30,
                  },
                 }}
                 className="my-5"
                >
                 {userVideos.length > 0 &&
                  userVideos.map((v, i) => (
                   <SwiperSlide key={`p-video${i}`}>
                    {" "}
                    <div className="item-slick">
                     <div className="post-item">
                      <div className="overlay" />
                      <div className="pic">
                       <a
                        href={`${
                         v.is_private === "yes"
                          ? `fan-video?vid=${v.id}`
                          : `single-video?vid=${v.id}`
                        }`}
                        className="btn-play"
                       >
                        <img
                         src={domain + v.cover_photo}
                         alt="IMG"
                         style={{
                          height: "300px",
                          objectFit: "cover",
                         }}
                        />
                       </a>
                      </div>
                      <h4 className="title">
                       <a
                        href={`${
                         v.is_private === "yes"
                          ? `fan-video?vid=${v.id}`
                          : `single-video?vid=${v.id}`
                        }`}
                        className="btn-play"
                        style={{ color: "#111" }}
                       >
                        {v.title}{" "}
                        {v.is_private == "yes" ? (
                         <i className="fa fa-lock text-danger" aria-hidden="true" />
                        ) : (
                         <i className="fa fa-globe text-success" aria-hidden="true" />
                        )}
                       </a>
                      </h4>
                     </div>
                    </div>
                   </SwiperSlide>
                  ))}
                </Swiper>
               </div>
              </div>
             </div>
            </div>
           </div>
          </div>
         </main>
        </div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default FanVideo;
