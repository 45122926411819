import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context/contex";
import "./style.css";
import SpinnerOverlay from "./Spinner";
import { formatNumber } from "../utils/helpers";
const Header = () => {
 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  user,
  getUser,
  loading,
  setLoading,
  buyCredits,
  domain,
  tokenPrice,
 } = useContext(Context);
 const [isMenuOpen, setMenuOpen] = useState(false);
 const [openSubMenus, setOpenSubMenus] = useState({});
 const [wallet, setWallet] = useState("");
 const [amount, setAmount] = useState("");

 const toggleMenu = () => {
  setMenuOpen(!isMenuOpen);
 };

 const addWallet = async (connection, wallet) => {
  if (!connection || !wallet) return;
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "add_user_wallet",
     wallet: wallet,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.added) {
    console.error("Error:", data.message);
   } else {
    getUser(address);
    //  setRelatedVideos(data);
    console.log("Success:", data.message);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  addWallet(isConnected, address);
 }, [isConnected, address]);

 return (
  <>
   <SpinnerOverlay loading={loading} />

   <header className="site-header sticky-header layout-1">
    <div className="header-container">
     <div className="top-header">
      <div className="container">
       <div className="wrap-content-header">
        <div className="header-logo">
         <a href="/" className="logo">
          <img src="assets/images/logo.png" alt="IMG" width={166} />
         </a>
         <a href="/" className="mobile-logo">
          <img src="assets/images/logo.png" alt="IMG" />
         </a>
         <a href="/" className="sticky-logo">
          <img src="assets/images/logo.png" alt="IMG" />
         </a>
        </div>
        <div className="right-logo">
         <div className="widget_thim_layout_builder">
          <div className="wpb_wrapper">
           <div className="bp-element bp-element-st-search-videos vblog-layout-header-1">
            <div className="wrap-element">
             <div className="search-form">
              <div className="search-fielda pl-3 text-white">
               1 18plus token = <span className="text-warning">${tokenPrice}</span>
              </div>
             </div>
            </div>
           </div>
           <div className="bp-element vblog-layout-header-1">
            <div className="wrap-element">
             <w3m-button balance="hide" />
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>

     <div className="bottom-header element-to-stick">
      <div className="container">
       <div className="wrap-content-header">
        <nav className="main-navigation">
         <ul className="menu-lists">
          <li className="menu-item-has-children">
           <a href="/"> Home </a>
          </li>

          <li className="menu-item-has-children">
           <a href="#"> Watch to Earn </a>
           <ul className="sub-menu">
            <li>
             <a href="/watch">Normal Videos </a>
            </li>
            <li>
             <a href="/watch-vr">VR Videos </a>
            </li>

            <li>
             <a href="/post-video"> Post Video </a>
            </li>
           </ul>
          </li>
          <li className="menu-item-has-children">
           <a href="#">Just Fans </a>
           <ul className="sub-menu">
            <li>
             <a href="/fan-real"> Real Creators </a>
            </li>
            <li>
             <a href="/fan-ai"> Ai Creators</a>
            </li>
            <li>
             <a href="/post-fan-video"> Post Video </a>
            </li>
            <li>
             <a href="/become-creator"> Become A Creator </a>
            </li>
           </ul>
          </li>
          <li className="menu-item-has-children">
           <a href="#"> Services </a>
           <ul className="sub-menu">
            <li>
             <a href="/porn-face"> Your porn face </a>
            </li>
            <li>
             <a href="/face-swap"> Face swap </a>
            </li>
           </ul>
          </li>
          <li className="menu-item-has-children">
           <a href="#"> NFTs </a>
           <ul className="sub-menu">
            <li>
             <a href="/profile-nft"> Profile NFT</a>
            </li>
            <li>
             <a href="/onchain-nft"> Nude collectors NFT</a>
            </li>
           </ul>
          </li>
          <li className="menu-item-has-children">
           <a href="#"> Market </a>
           <ul className="sub-menu">
            <li>
             <a href="/shop"> Marketplace</a>
            </li>
           </ul>
          </li>
          <li className="menu-item-has-children">
           <a href="#"> Contact </a>
          </li>
         </ul>
        </nav>
        <div className="menu-right">
         {isConnected && (
          <div className="bp-element bp-element-button">
           <a
            href={`profile?user=${user.username !== null ? user.username : user.wallet}`}
            className="btn"
           >
            <img
             src={domain + user.profile_photo}
             height={20}
             width={20}
             style={{ borderRadius: "100%", marginRight: "5px" }}
            />{" "}
            MY PROFILE
           </a>
          </div>
         )}
         <div className="bp-element bp-element-button">
          <a href={`${domain}assets/WP-Coin18.pdf`} className="btn" target="_blank">
           <i className="fa fa-book" />
           Whitepaper
          </a>
         </div>
         <div className="bp-element bp-element-button">
          <a data-toggle="modal" data-target="#buyModal" className="btn">
           <i className="fa fa-usd" />
           Buy v18plus
          </a>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </header>

   {/*BUY CREDITS modal*/}
   <div
    className="modal fade"
    id="buyModal"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="buyModalLabel"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-md" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <h3 className="modal-title" id="buyModalLabel">
        Buy v18plus for self or friends
       </h3>
       <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <div className="mb-2">
        <input
         type="text"
         style={{
          background: "#111",
          height: "50px",
          color: "#fff",
          width: "100%",
         }}
         placeholder="Paste recipient's wallet"
         onInput={(e) => setWallet(e.target.value)}
         value={wallet}
        />
       </div>

       <div>
        <input
         type="number"
         style={{
          background: "#111",
          height: "50px",
          color: "#fff",
          width: "100%",
         }}
         placeholder="Amount"
         onInput={(e) => setAmount(e.target.value)}
         value={amount}
        />
       </div>
      </div>

      <div className="modal-footer">
       <button type="button" className="btn btn-secondary" data-dismiss="modal">
        I'm done
       </button>
       {isConnected ? (
        <button type="button" className="btn btn-custom" onClick={() => buyCredits(wallet, amount)}>
         Pay {amount && formatNumber(amount)} tokens
        </button>
       ) : (
        <button disabled={true} type="button" className="btn btn-custom">
         Connect your wallet first.
        </button>
       )}
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

export default Header;
