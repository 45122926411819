import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import { formatNumber, formatLocalTime, shortenAddress } from "../../utils/helpers";
import axios from "axios";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import Pagination2 from "../../components/Pagination2";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function WatchHome() {
 const videoRefs = useRef([]);
 const sidebarRef = useRef(null);
 const [categories, setCategories] = useState([]);
 const [category, setCategory] = useState("all");
 const [vquery, setVQuery] = useState("");
 const [isLoading, setIsLoading] = useState(false);
 const [vresults, setVResults] = useState([]);

 const [newVideos, setNewVideos] = useState([]);
 const [popularVideos, setPopularVideos] = useState([]);
 const [popularCategories, setPopularCategories] = useState([]);
 const [pagination, setPagination] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [popularPage, setPopularPage] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [video, setVideo] = useState([]);

 const { apiURL, domain, setLoading } = useContext(Context);

 const [hoveredIndex, setHoveredIndex] = useState(null);

 useEffect(() => {
  if (vquery.length > 0) {
   const fetchData = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("query", vquery);
    //formData.append("type", type);

    formData.append("action", "search_watch_videos");
    try {
     const response = await axios.post(apiURL, formData, {
      headers: {
       "Content-Type": "application/x-www-form-urlencoded",
      },
     });
     setVResults(response.data);
    } catch (error) {
     console.error("Error fetching search results", error);
    }
    setIsLoading(false);
   };

   const debounceTimeout = setTimeout(() => {
    fetchData();
   }, 300); // Adjust the debounce delay as needed

   return () => clearTimeout(debounceTimeout);
  } else {
   setVResults([]);
  }
 }, [vquery]);

 const getCategories = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_categories" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setCategories(data);
    //  console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getCategories();
 }, []);

 const handleMouseEnter = (index) => {
  setHoveredIndex(index);
 };

 const handleMouseLeave = () => {
  setHoveredIndex(null);
 };

 const openVideo = (id, index) => {
  if (videoRefs.current[index]) {
   videoRefs.current[index].pause();
   videoRefs.current[index].currentTime = 0;
  }

  setHoveredIndex(null);

  window.open(`single-video?vid=${id}`, "_blank");
 };

 const getVideo = async (page = 1, category, limit = 40) => {
  setLoading(true);
  setVideo([]);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "get_latest_watch_videos",
     page: page,
     limit: limit,
     category: category,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setVideo(data.records);
    setPagination(data.pagination);
    // console.log("Fetched Video URL:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getVideo(1, category);
 }, [category]);

 const getNewVideos = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_new_watch_videos" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setNewVideos(data);
    console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getNewVideos();
 }, []);

 const getPopularVideos = async (page = 1, limit = 4) => {
  setLoading(true);

  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_popular_watch_videos", page: page, limit: limit }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setPopularVideos(data.records);
    setPopularPage(data.pagination);

    console.log("P:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getPopularVideos(1);
 }, []);

 const getPopularCategories = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_popular_watch_categories" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setPopularCategories(data);
    console.log("Alive CATS:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getPopularCategories();
 }, []);

 const handleToggle = () => {
  if (sidebarRef.current) {
   sidebarRef.current.classList.toggle("show");
  }
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content">
     <div className="content-area">
      <div className="site-content layout-1">
       <div className="container-fluid">
        <div className="d-flex">
         <div className="sidebar-admin" ref={sidebarRef} style={{ background: "#f5f5f5" }}>
          <div className="text-right">
           <button className="btn btn-danger d-md-none mb-3" onClick={handleToggle}>
            <i style={{ fontSize: "30px" }} className="fa fa-times-circle" aria-hidden="true" />
           </button>
          </div>
          <div className="list-group list-group-flush">
           <h3 className="p-4 text-capitalize">CATEGORIES</h3>
           <button
            className={`list-group-item list-group-item-action ${category === "all" && "active"}`}
            onClick={() => setCategory("all")}
            style={{ background: "#f5f5f5", color: "#111" }}
           >
            <i className="fa fa-fort-awesome" aria-hidden="true"></i>
            <span>All</span>
           </button>
           {categories.length &&
            categories.map((cat, i) => (
             <button
              key={i}
              className={`list-group-item list-group-item-action ${
               category === cat.category && "active"
              }`}
              onClick={() => setCategory(cat.category)}
              style={{ background: "#f5f5f5", color: "#111" }}
             >
              <i className="fa fa-fort-awesome" aria-hidden="true"></i>
              <span>{cat.category}</span>
             </button>
            ))}
          </div>
         </div>
         <div className="main-content flex-grow-1 w-100">
          <button className="btn btn-outline-danger d-md-none mb-3" onClick={handleToggle}>
           <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
          <div className="container-fluid">
           <>
            <div className="row">
             <div className="col-md-2" />
             <div className="col-md-8">
              <input
               placeholder="Search videos by creators wallet or title"
               className="form-control my-5"
               style={{
                width: "100%",
                height: "60px",
                border: "#aaa 1px solid",
               }}
               type="search"
               value={vquery}
               onChange={(e) => setVQuery(e.target.value)}
              />
              {!isLoading && (
               <div>
                {vresults.length > 0 &&
                 vresults.map((v, i) => (
                  <>
                   <a href={`single-video?vid=${v.id}`}>
                    <div className="card nft-card">
                     <div className="card-body">
                      <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                       <img
                        src={domain + v.cover_photo}
                        className="card-img-topa"
                        alt="Avatar"
                        style={{ height: "70px" }}
                       />
                       <div>
                        <h5 className="card-title">{v.title}</h5>

                        <p className="price">{v.views} views</p>
                       </div>
                      </div>
                     </div>
                    </div>
                   </a>
                  </>
                 ))}
               </div>
              )}
              {isLoading && <p>Loading...</p>}
             </div>
             <div className="col-md-2" />
            </div>
            <div className="row">
             {video.length > 0 &&
              video.map((v, i) => (
               <div key={i} className="col-md-3 col-xl-3 col-sm-6 my-2 vid-card">
                <div
                 className="item-slick"
                 onMouseEnter={() => handleMouseEnter(i)}
                 onMouseLeave={handleMouseLeave}
                 onClick={() => openVideo(v.id, i)}
                >
                 <div className="post-item">
                  <div className="pic" style={{ position: "relative" }}>
                   {/* Show video preview on hover, otherwise show image */}
                   {hoveredIndex === i ? (
                    <video
                     ref={(el) => (videoRefs.current[i] = el)}
                     src={domain + v.video}
                     autoPlay
                     muted
                     loop
                    />
                   ) : (
                    <img src={domain + v.cover_photo} alt="IMG" className="vr-slider" />
                   )}

                   {/* Hide overlay and meta info on hover */}
                   {hoveredIndex !== i && (
                    <>
                     <div className="label">{v.category}</div>
                    </>
                   )}
                  </div>
                 </div>
                </div>
                <a href={`single-video?vid=${v.id}`}>
                 <h4 className="title">{v.title}</h4>
                </a>
                <div className="d-flex align-items-center">
                 <img src={domain + v.profile_photo} className="vavatar" alt="Avatar" />
                 <a
                  href={`profile?user=${!v.username ? v.wallet : v.username}`}
                  target="_blank"
                  className="ml-2"
                 >
                  {!v.username ? shortenAddress(v.wallet) : v.username}
                 </a>
                 {v.verified === "yes" && (
                  <i className="fa fa-check-circle text-success ml-1" aria-hidden="true" />
                 )}
                 <span className="ml-auto">
                  <i className="fa fa-eye" aria-hidden="true" /> {v.views}
                 </span>
                </div>
               </div>
              ))}
             {video.length === 0 && (
              <div className="col-12 text-center">
               <img src="assets/images/fly.gif" height={100} />
               <p>No videos here yet...</p>
              </div>
             )}
            </div>
            {video.length > 0 && (
             <Pagination2
              currentPage={pagination.current_page}
              totalPages={pagination.total_pages}
              onPageChange={(page) => getVideo(page, category)}
             />
            )}
            <div className="row mt-5">
             <div className="col-12 my-5 text-center">
              <h2>TRENDING VIDEOS</h2>
             </div>
             {popularVideos.length > 0 &&
              popularVideos.map((v, i) => (
               <div key={i} className="col-md-3 col-xl-3 col-sm-6 my-2 vid-card">
                <div
                 className="item-slick"
                 onMouseEnter={() => handleMouseEnter(i)}
                 onMouseLeave={handleMouseLeave}
                 onClick={() => openVideo(v.id, i)}
                >
                 <div className="post-item">
                  <div className="pic" style={{ position: "relative" }}>
                   {/* Show video preview on hover, otherwise show image */}
                   {hoveredIndex === i ? (
                    <video
                     ref={(el) => (videoRefs.current[i] = el)}
                     src={domain + v.video}
                     autoPlay
                     muted
                     loop
                    />
                   ) : (
                    <img src={domain + v.cover_photo} alt="IMG" className="vr-slider" />
                   )}

                   {/* Hide overlay and meta info on hover */}
                   {hoveredIndex !== i && (
                    <>
                     <div className="label">{v.category}</div>
                    </>
                   )}
                  </div>
                 </div>
                </div>
                <a href={`single-video?vid=${v.id}`}>
                 <h4 className="title">{v.title}</h4>
                </a>
                <div className="d-flex align-items-center">
                 <img src={domain + v.profile_photo} className="vavatar" alt="Avatar" />
                 <a
                  href={`profile?user=${!v.username ? v.wallet : v.username}`}
                  target="_blank"
                  className="ml-2"
                 >
                  {!v.username ? shortenAddress(v.wallet) : v.username}
                 </a>
                 {v.verified === "yes" && (
                  <i className="fa fa-check-circle text-success ml-1" aria-hidden="true" />
                 )}
                 <span className="ml-auto">
                  <i className="fa fa-eye" aria-hidden="true" /> {v.views}
                 </span>
                </div>
               </div>
              ))}
             {video.length === 0 && (
              <div className="col-12 text-center">
               <img src="assets/images/fly.gif" height={100} />
               <p>No videos here yet...</p>
              </div>
             )}
            </div>

            <div className="row mt-5">
             {popularCategories.length === 0 && (
              <div className="col-12 text-center">
               <img src="assets/images/fly.gif" height={100} />
               <p>No categories here yet...</p>
              </div>
             )}
            </div>
           </>
          </div>
         </div>
        </div>
        <div className="swiper-container" style={{ maxWidth: "100%", overflow: "hidden" }}>
         <div className="text-center">
          <h2>TRENDING CATEGORIES</h2>
         </div>
         <Swiper
          navigation={true}
          modules={[Pagination, Autoplay, Navigation]}
          autoplay
          pagination={{ clickable: true }}
          loop={true}
          breakpoints={{
           640: {
            slidesPerView: 1,
            spaceBetween: 20,
           },
           768: {
            slidesPerView: 2,
            spaceBetween: 30,
           },
           1024: {
            slidesPerView: 4,
            spaceBetween: 30,
           },
          }}
          className="my-5"
          style={{ width: "auto", maxWidth: "100%" }} // Limit width of Swiper
         >
          {popularCategories.length > 0 &&
           popularCategories.map((v, i) => (
            <SwiperSlide key={`p-video${i}`}>
             <div className="item-slick">
              <div className="post-item">
               <div className="overlay" />
               <div className="pic">
                <a href={`categories?cat=${v.category}`} className="btn-play">
                 <img
                  src={`${domain}assets/images/categories/${v.image}`}
                  alt="IMG"
                  style={{
                   height: "200px",
                   objectFit: "cover",
                   width: "100%", // Ensure image fits in its container
                  }}
                 />
                </a>
               </div>
               <h4 className="title" style={{ textAlign: "center" }}>
                <a
                 href={`categories?cat=${v.category}`}
                 className="btn-play"
                 style={{ color: "#111", textAlign: "center" }}
                >
                 {v.category} ({v.post_count})
                </a>
               </h4>
              </div>
             </div>
            </SwiperSlide>
           ))}
         </Swiper>
        </div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default WatchHome;
