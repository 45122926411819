import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import { ReadContract } from "../../utils/readContract";
import "../styles/post-video.css";
import "./profile.css";
import usePagination from "../../components/usePagination";
import Pagination from "../../components/Pagination";

import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 showAlert,
 searchArray,
 searchArrayBool,
 generateNonce,
} from "../../utils/helpers";
import axios from "axios";

import { useQueryParam } from "../../components/useQueryParam";

import { ethers, parseUnits } from "ethers";
function Profile() {
 const [userVideos, setUserVideos] = useState([]);
 const [userFans, setUserFans] = useState([]);
 const [userCreators, setUserCreators] = useState([]);
 const [pagination, setPagination] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [profile, setProfile] = useState({});
 const [profileOwner, setProfileOwner] = useState(false);
 const [canClaim, setCanClaim] = useState(false);
 const [text, setText] = useState("");
 const [inboxText, setInboxText] = useState("");
 const [vtype, setVtype] = useState("all");

 const [unread, setUnread] = useState(0);
 const [alerts, setAlerts] = useState([]);
 const [isFan, setIsFan] = useState(false);
 const [creditGift, setCreditGift] = useState("");

 const [inboxImages, setInboxImages] = useState([]);

 const [images, setImages] = useState([]);
 const [senders, setSenders] = useState([]);
 const [conversation, setConversation] = useState([]);
 const [activeChat, setActiveChat] = useState({});
 const itemsPerPage = 20;
 const { paginatedData, appendData, resetPagination, currentPage } = usePagination(itemsPerPage);

 const [param, setParam] = useState(null);
 const query = useQueryParam("user");

 const {
  address,

  isConnected,
  tokenBalance,
  apiURL,
  user,
  setLoading,
  settings,
  withdraw,
  domain,
  tokenPrice,
  getUser,
 } = useContext(Context);

 useEffect(() => {
  if (!query) {
   setParam(null);
  } else {
   setParam(query);
  }
 }, [query]);

 const sendMessage = async () => {
  if (address === profile.wallet) {
   showAlert({
    title: "Error!",
    text: "You cannot start a conversation with yourself.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  const formData = new FormData();
  formData.append("action", "send_message");
  formData.append("sender", address);
  formData.append("receiver", profile.wallet);
  formData.append("chat", text);

  images.forEach((image, index) => {
   formData.append(`files[]`, image);
  });

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });

  if (response.data.success) {
   setText("");
   setImages([]);
   // fetchMessages();
   console.log("sent", response.data);
  } else {
   showAlert({
    title: "Error!",
    text: "Message sending failed",
    icon: "error",
    confirmButtonText: "Ok",
   });
   console.log("not sent", response);
  }
 };

 const sendMessageInbox = async () => {
  if (address === activeChat.sender) {
   showAlert({
    title: "Error!",
    text: "You cannot start a conversation with yourself.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  const formData = new FormData();
  formData.append("action", "send_message");
  formData.append("sender", address);
  formData.append("receiver", activeChat.sender);
  formData.append("chat", inboxText);

  inboxImages.forEach((image, index) => {
   formData.append(`files[]`, image);
  });

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });

  if (response.data.success) {
   setInboxText("");
   setInboxImages([]);
   fetchMessagesInbox();
   console.log("sent", response.data);
  } else {
   showAlert({
    title: "Error!",
    text: "Message sending failed",
    icon: "error",
    confirmButtonText: "Ok",
   });
   console.log("not sent", response);
  }
 };

 useEffect(() => {
  if (Object.keys(activeChat).length == 0) {
   setConversation([]);
   return;
  }
  fetchMessagesInbox();
 }, [address]);

 const canWithdraw = async () => {
  try {
   const contract = await ReadContract();

   const time = await contract.canWithdraw();
   //  console.log("ENDED", time);

   setCanClaim(time);
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  canWithdraw();
 }, []);

 const getProfile = async (wallet) => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_user_info", wallet: wallet }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setProfile(data[0]);

    console.log("Fetched profile:", data[0]);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!param) {
   setProfile({});
  } else {
   getProfile(param);
  }
 }, [param, user]);

 const getUserVideos = async (wallet, vtype = "all", page = 1, limit = 16) => {
  if (!wallet || wallet == null) return;
  setLoading(true);
  try {
   const details = {
    action: "get_user_videos",
    wallet: wallet,
    page: page,
    limit: limit,
    vtype: vtype,
   };
   if (profile.username != null) {
    details.is_username = "yes";
   }
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserVideos(data.records);
    setPagination(data.pagination);

    console.log("All videos:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  if (!param || param == null) return;
  getUserVideos(param, vtype);
 }, [param, profile, vtype]);

 const getUserAlerts = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_notifications",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setAlerts(data);
    console.log("ALERTS:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getUserAlerts(address);
 }, [address, isConnected]);

 const getUserFans = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_user_fans",
    wallet: wallet,
   };
   if (profile.username != null) {
    details.is_username = "yes";
   }
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserFans(data);
    //  console.log("All fans:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!param || param == null) return;
  getUserFans(param);
 }, [param, profile]);

 const getUserCreators = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_user_creators",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserCreators(data);
    //  console.log("All fans:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!address || !isConnected) return;
  getUserCreators(address);
 }, [address, isConnected]);

 useEffect(() => {
  if (isConnected && profile.wallet === address) {
   setProfileOwner(true);
  } else {
   setProfileOwner(false);
  }
 }, [isConnected, address, profile]);

 const handleLike = async (videoId) => {
  if (!videoId || videoId == null) return;
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Connect a wallet to like this post.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  try {
   const details = {
    action: "like_video",
    vid: videoId,
    wallet: address,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.success) {
    // console.error("NO LIKE:", data.message);
   } else {
    getUserVideos(param);
    // setUserFans(data);
    // console.log("LIKED:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 const handleDelete = async (vid) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  const confirmDelete = window.confirm("Are you sure you want to delete this video?");
  if (!confirmDelete) return;

  const formData = new FormData();
  formData.append("vid", vid);
  formData.append("action", "delete_video_post");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   if (response.data.success) {
    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    getUserVideos(param);
   } else {
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while deleting the video. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
 };
 /*
  useEffect(() => {
    fetchMessages();
    const interval = setInterval(() => {
      fetchMessages();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  */

 const updateRead = async (senderId, receiverId) => {
  // console.log("THE", chat);
  const formData = new FormData();
  formData.append("sender", senderId);
  formData.append("receiver", receiverId);
  formData.append("action", "update_unread_chat");

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "application/x-www-form-urlencoded",
   },
  });
  // console.log("THE", response);

  if (response.data.success) {
   //setConversation(response.data.messages);
   // setActiveChat(chat);
   console.log("UPDATE", response.data.status);
  }
 };

 const fetchMessages = async (senderId, receiverId, chat) => {
  // console.log("THE", chat);
  const formData = new FormData();
  formData.append("sender_id", senderId);
  formData.append("receiver_id", receiverId);
  formData.append("action", "get_messages");

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "application/x-www-form-urlencoded",
   },
  });
  // console.log("THE", response);

  if (response.data.success) {
   setConversation(response.data.messages);
   setActiveChat(chat);
   updateRead(senderId, receiverId);
   fetchSenders();
   //   console.log("THESENDER", response);
  }
 };

 const fetchMessagesInbox = async () => {
  // console.log("THE", chat);
  const formData = new FormData();
  formData.append("sender_id", activeChat.sender);
  formData.append("receiver_id", address);
  formData.append("action", "get_messages");

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "application/x-www-form-urlencoded",
   },
  });
  // console.log("THE", response);

  if (response.data.success) {
   setConversation(response.data.messages);
   //  updateRead(senderId, receiverId);
   //  setActiveChat(chat);
   //   console.log("THESENDER", response);
  }
 };

 const fetchSenders = async () => {
  if (!isConnected) {
   return;
  }

  const formData = new FormData();
  formData.append("receiver_wallet", address);
  formData.append("action", "get_chat_head");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
   });

   if (response.data.length > 0) {
    console.log("senders:", response.data);

    setSenders(response.data);
   } else {
    setSenders([]);
   }
  } catch (error) {
   console.error("Error:", error);
  }
 };

 useEffect(() => {
  fetchSenders();
 }, [isConnected, address]);

 useEffect(() => {
  const fetchUnread = async () => {
   if (!isConnected) {
    return;
   }

   const formData = new FormData();
   formData.append("receiver", address);
   formData.append("action", "get_unread_chat");

   try {
    const response = await axios.post(apiURL, formData, {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    });

    if (response.data.success) {
     //  console.log("UNREAD:", response.data);

     setUnread(response.data.unread);
    } else {
     // console.log("AUNREAD:", response.data);

     setUnread(0);
    }
   } catch (error) {
    console.error("Error:", error);
   }
  };

  fetchUnread();
  const interval = setInterval(() => {
   fetchUnread();
  }, 3000);
  return () => clearInterval(interval);
 }, [isConnected, address]);

 useEffect(() => {
  if (userFans.length === 0) {
   return;
  }
  setIsFan(searchArray(userFans, address));
 }, [userFans]);

 const signMessage = async () => {
  if (!canClaim) {
   return showAlert({
    title: "ERROR!",
    text: "Withdrawals are currently disabled.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits === 0) {
   return showAlert({
    title: "ERROR!",
    text: "You have no v18plus tokens to withdraw.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }

  try {
   const wallet = new ethers.Wallet(settings.project_id);
   const nonce = generateNonce();
   // Create message hash
   const messageHash = ethers.solidityPackedKeccak256(
    ["address", "uint256", "string"],
    [address, parseUnits(user.credits, 18), nonce]
   );

   // Sign the message hash
   const signature = await wallet.signMessage(ethers.toBeArray(messageHash));
   // setSignedMessage(signature);
   console.log("SIG", signature);
   // Call smart contract function with the signed message and balance
   await withdraw(signature, nonce);
  } catch (error) {
   console.error("Error signing message:", error);
  }
 };

 const handleSubscribe = async (creator, amount) => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits < amount) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "add_fan",
     creator: creator,
     fan: address,
     amount: amount,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    await getUserFans(param);
    await getUser(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    //  setIsFan(true);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 const sendGift = async () => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (!creditGift) {
   return showAlert({
    title: "ERROR!",
    text: "Enter v18plus amount.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits < creditGift) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "gift_credit",
     from: address,
     to: profile.wallet,
     amount: creditGift,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    await getUser(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    //  setIsFan(true);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    {param && (
     <div id="main-content" style={{ background: "#1e1e1e" }}>
      <div className="content-area">
       <div className="container-fluid">
        <div className="profile-section">
         <img
          src={domain + profile.cover_photo}
          className="profile-cover-image"
          alt="Cover Image"
         />
         <img src={domain + profile.profile_photo} className="profile-avatar" alt="Avatar" />
         <div className="profile-details">
          <h4>
           {!profile.username ? shortenAddress(profile.wallet) : profile.username} (Unique NFT ID:{" "}
           {profile.id}){" "}
           {profile.verified === "yes" && <i className="fa fa-check-circle" aria-hidden="true" />}
          </h4>

          <p className="divides">{profile.bio}</p>
          <div className="d-flex divides mt-2">
           <span className="p-2">
            {" "}
            {parseInt(formatNumber(userVideos.length))} Posts{" "}
            <i className="fa fa-th" aria-hidden="true" />
           </span>
           <span className="p-2">
            {" "}
            {userFans.length} Fans <i className="fa fa-heart" aria-hidden="true" />
           </span>
           {profileOwner && (
            <>
             <span className="p-2">
              {" "}
              <i className="fa fa-usd" aria-hidden="true" />
              {formatNumber(user.credits)} v18plus
             </span>
            </>
           )}
          </div>
          <div className="d-flex mt-3">
           {!profileOwner && profile.is_creator === "yes" && (
            <>
             {!isFan ? (
              <button className="btn btn-warning mr-2" data-toggle="modal" data-target="#subModal">
               Subscribe <i className="fa fa-heart" aria-hidden="true" />
              </button>
             ) : (
              <button className="btn btn-warning" data-toggle="modal" data-target="#msgModal">
               Message <i className="fa fa-envelope" aria-hidden="true" />
              </button>
             )}
            </>
           )}
           {profileOwner && (
            <button className="btn btn-warning mr-2" onClick={signMessage}>
             Withdraw earnings
            </button>
           )}
           {!profileOwner && (
            <button className="btn btn-warning mr-2" data-toggle="modal" data-target="#giftModal">
             Gift me <i className="fa fa-usd" aria-hidden="true" />
            </button>
           )}
          </div>
         </div>
        </div>
       </div>
       <div className="site-content layout-1">
        <div className="container">
         {/* Tabs Section */}
         <div className="tabs-section">
          <ul className="nav nav-tabs pb-5" id="myTab" role="tablist">
           <li className="nav-item">
            <a
             className="nav-link active"
             id="timeline-tab"
             data-toggle="tab"
             href="#timeline"
             role="tab"
             aria-controls="timeline"
             aria-selected="true"
            >
             Timeline
            </a>
           </li>
           {profile.is_creator === "yes" && (
            <li className="nav-item">
             <a
              className="nav-link"
              id="fans-tab"
              data-toggle="tab"
              href="#fans"
              role="tab"
              aria-controls="fans"
              aria-selected="false"
             >
              Fans
             </a>
            </li>
           )}
           {profileOwner && (
            <>
             <li className="nav-item">
              <a
               className="nav-link"
               id="creators-tab"
               data-toggle="tab"
               href="#creators"
               role="tab"
               aria-controls="My Creators"
               aria-selected="false"
              >
               My Creators
              </a>
             </li>

             <li className="nav-item">
              <a
               className="nav-link"
               id="inbox-tab"
               data-toggle="tab"
               href="#inbox"
               role="tab"
               aria-controls="inbox"
               aria-selected="false"
              >
               Inbox{" "}
               {unread > 0 && (
                <sup>
                 <span class="badge badge-danger">{unread}</span>
                </sup>
               )}
              </a>
             </li>

             <li className="nav-item">
              <a
               className="nav-link"
               id="notice-tab"
               data-toggle="tab"
               href="#notifications"
               role="tab"
               aria-controls="notifications"
               aria-selected="false"
              >
               Alerts
              </a>
             </li>
            </>
           )}
           <li className="nav-item">
            <a
             className="nav-link"
             id="profile-tab"
             data-toggle="tab"
             href="#profile"
             role="tab"
             aria-controls="profile"
             aria-selected="false"
            >
             Profile
            </a>
           </li>
          </ul>
          <div className="tab-content" id="myTabContent">
           <div
            className="tab-pane fade show active"
            id="timeline"
            role="tabpanel"
            aria-labelledby="timeline-tab"
           >
            {/* Timeline content */}
            {/* Timeline content */}
            <div className="row">
             <div className="col-12 text-center">
              <select onChange={(e) => setVtype(e.target.value)}>
               <option value="all" {...(vtype === "all" ? "selected" : "")}>
                All videos
               </option>
               <option value="public" {...(vtype === "public" ? "selected" : "")}>
                Public videos
               </option>
               <option value="private" {...(vtype === "private" ? "selected" : "")}>
                Private videos
               </option>
              </select>
             </div>
             {userVideos.length > 0 &&
              userVideos.map((v, i) => (
               <div key={i} className="timeline-post col-md-6 my-3">
                <div className="post-header">
                 <div className="user-info">
                  <img src={domain + v.profile_photo} alt="User Avatar" />
                  <div>
                   <p className="user-name">
                    {!v.username ? shortenAddress(v.wallet) : v.username}{" "}
                    {v.verified === "yes" && (
                     <i className="fa fa-check-circle" aria-hidden="true" />
                    )}
                   </p>
                   <p className="post-date">
                    {formatLocalTime(v.post_date)}{" "}
                    {v.is_private == "yes" ? (
                     <i className="fa fa-lock text-danger" aria-hidden="true" />
                    ) : (
                     <i className="fa fa-globe text-success" aria-hidden="true" />
                    )}
                   </p>
                  </div>
                 </div>
                 {profileOwner && (
                  <div className="post-options">
                   <i
                    style={{ cursor: "pointer" }}
                    className={`fa fa-edit text-primary mr-2`}
                    onClick={() =>
                     window.open(`/edit-post?vid=${v.id}`, "_blank", "noopener,noreferrer")
                    }
                   />
                   <i
                    style={{ cursor: "pointer" }}
                    className={`fa fa-trash mr-2 text-danger`}
                    onClick={() => handleDelete(v.id)}
                   />
                  </div>
                 )}
                </div>
                <div className="post-content">
                 <h5>{v.title}</h5>
                 <div className="post-video-container">
                  <a
                   href={`${
                    v.is_private === "yes" ? `fan-video?vid=${v.id}` : `single-video?vid=${v.id}`
                   }`}
                   className="btn-play"
                  >
                   <img
                    src={domain + v.cover_photo}
                    alt={v.title}
                    className="video-cover"
                    style={{ height: "300px", objectFit: "cover" }}
                   />
                   <div className="play-icon">
                    <i className="fa fa-play" />
                   </div>
                  </a>
                 </div>
                </div>
                <div className="post-actions">
                 <div className="action-buttons">
                  <button style={{ color: "#a11a96" }} onClick={() => handleLike(v.id)}>
                   <i className="fa fa-thumbs-up" />{" "}
                   {searchArrayBool(v.liked_by_users, address) ? "Unlike" : "Like"}
                  </button>
                  <button
                   className="text-primary"
                   data-toggle="modal"
                   data-target={`#likeModal-${i}`}
                  >
                   {Math.round(formatNumber(v.like_count))}
                  </button>
                 </div>
                 <div className="comment-count">{Math.round(formatNumber(v.views))} Views</div>
                </div>
                {/*LIKES modal*/}
                <div
                 className="modal fade"
                 id={`likeModal-${i}`}
                 tabIndex={-1}
                 role="dialog"
                 aria-labelledby={`likeModalLabel-${i}`}
                 aria-hidden="true"
                >
                 <div className="modal-dialog modal-sm" role="document">
                  <div className="modal-content">
                   <div className="modal-header">
                    <h3 className="modal-title" id={`likeModalLabel-${i}`}>
                     Video Likes
                    </h3>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">×</span>
                    </button>
                   </div>
                   <div className="modal-body">
                    {v.liked_by_users.length > 0 &&
                     v.liked_by_users.map((u, index) => (
                      <div key={index} className="d-flex align-items-center p-2">
                       <img
                        src={domain + u.profile_photo}
                        style={{
                         height: "30px",
                         width: "30px",
                         borderRadius: "50%",
                        }}
                       />
                       <span className="ml-1">
                        {" "}
                        <a
                         href={`profile?user=${!u.username ? u.wallet : u.username}`}
                         style={{ color: "#fff" }}
                         target="_blank"
                        >
                         {!u.username ? shortenAddress(u.wallet) : u.username}
                        </a>{" "}
                       </span>
                      </div>
                     ))}
                   </div>

                   <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">
                     Close
                    </button>
                   </div>
                  </div>
                 </div>
                </div>
               </div>
              ))}
            </div>
            {userVideos.length === 0 && (
             <div className="text-center">
              <img src="assets/images/fly.gif" height={100} />
              <p>No posts here yet...</p>
             </div>
            )}
            {userVideos.length > 0 && (
             <Pagination
              currentPage={pagination.current_page}
              totalPages={pagination.total_pages}
              onPageChange={(page) => getUserVideos(param, vtype, page)}
             />
            )}
            {/* Add more posts as needed */}
           </div>
           <div className="tab-pane fade" id="fans" role="tabpanel" aria-labelledby="fans-tab">
            {/* Fans content */}
            {userFans.length > 0 ? (
             <div className="fans-list">
              {userFans.map((fan, i) => (
               <div key={i} className="fan-item">
                <a
                 href={`profile?user=${!fan.username ? fan.wallet : fan.username}`}
                 target="_blank"
                >
                 <img src={domain + fan.profile_photo} alt="Fan Avatar" className="fan-avatar" />
                 <p className="fan-username">
                  {!fan.username ? shortenAddress(fan.wallet) : fan.username}{" "}
                  {fan.verified === "yes" && (
                   <i className="fa fa-check-circle" aria-hidden="true" />
                  )}
                 </p>
                </a>
               </div>
              ))}

              {/* Add more fan items as needed */}
             </div>
            ) : (
             <div className="text-center">
              <img src="assets/images/fly.gif" height={100} />
              <p>No fans here yet...</p>
             </div>
            )}
           </div>
           <div
            className="tab-pane fade"
            id="creators"
            role="tabpanel"
            aria-labelledby="creators-tab"
           >
            {/* Fans content */}
            {userCreators.length > 0 ? (
             <div className="fans-list">
              {userCreators.map((fan, i) => (
               <div key={i} className={`fan-item ${fan.is_active === 0 && "in-active-fan"}`}>
                <a
                 href={`profile?user=${!fan.username ? fan.wallet : fan.username}`}
                 target="_blank"
                >
                 <img src={domain + fan.profile_photo} alt="Fan Avatar" className="fan-avatar" />
                 <p className="fan-username">
                  {!fan.username ? shortenAddress(fan.wallet) : fan.username}{" "}
                  {fan.verified === "yes" && (
                   <i className="fa fa-check-circle" aria-hidden="true" />
                  )}
                 </p>
                </a>
               </div>
              ))}

              {/* Add more fan items as needed */}
             </div>
            ) : (
             <div className="text-center">
              <img src="assets/images/fly.gif" height={100} />
              <p>No creators here yet...</p>
             </div>
            )}
           </div>
           <div className="tab-pane fade" id="inbox" role="tabpanel" aria-labelledby="inbox-tab">
            {/* INBOX content */}
            <div className="chat-container">
             <div className="chat-sidebar">
              <div className="chat-sidebar-header">
               <h3>Conversations</h3>
              </div>
              <div className="chat-sidebar-content">
               {senders.length > 0 ? (
                senders.map((s, i) => (
                 <div
                  key={i}
                  className={`chat-user ${activeChat.sender === s.sender && "hover"}`}
                  onClick={() => fetchMessages(s.sender, address, s)}
                 >
                  <img src={domain + s.profile_photo} alt="User Avatar" className="chat-avatar" />
                  <div className="chat-user-info">
                   <p className="chat-username">
                    {!s.username ? shortenAddress(s.sender) : s.username}{" "}
                    {s.verified === "yes" && (
                     <i className="fa fa-check-circle" aria-hidden="true" />
                    )}
                    {s.is_read === 0 && (
                     <sup>
                      <span class="badge badge-danger">NEW</span>
                     </sup>
                    )}
                   </p>
                   <p className="chat-last-message">Since {formatLocalTime(s.add_date)}</p>
                  </div>
                 </div>
                ))
               ) : (
                <div className="text-center">
                 <img src="assets/images/fly.gif" height={100} />
                 <p>You have no messages yet...</p>
                </div>
               )}

               {/* Add more chat users as needed */}
              </div>
             </div>
             <div className="chat-main">
              <div className="chat-header">
               {Object.keys(activeChat).length > 0 && (
                <>
                 <img
                  src={domain + activeChat.profile_photo}
                  alt="Chat User Avatar"
                  className="chat-avatar"
                 />
                 <p className="chat-username">
                  {" "}
                  {!activeChat.username
                   ? shortenAddress(activeChat.sender)
                   : activeChat.username}{" "}
                  {activeChat.verified === "yes" && (
                   <i className="fa fa-check-circle" aria-hidden="true" />
                  )}
                 </p>
                </>
               )}
              </div>

              <div className="chat-content">
               {conversation.length > 0 &&
                conversation.map((msg, index) => (
                 <div
                  key={index}
                  className={`chat-message ${
                   msg.receiver === address ? "chat-message-sent" : "chat-message-received"
                  }`}
                 >
                  {msg.message && <p>{msg.message}</p>}
                  {msg.images.length > 0 &&
                   msg.images.map((image, imgIndex) => (
                    <div key={imgIndex}>
                     <img
                      className="my-2"
                      src={domain + image}
                      alt={`image-${imgIndex}`}
                      height={200}
                     />
                    </div>
                   ))}
                  <span className="chat-timestamp">{formatLocalTime(msg.add_date)}</span>
                 </div>
                ))}

               {/* Add more chat messages as needed */}
              </div>
              {conversation.length > 0 && (
               <div className="chat-footer">
                <input
                 type="text"
                 className="chat-input"
                 placeholder="Type a message..."
                 value={inboxText}
                 onChange={(e) => setInboxText(e.target.value)}
                />
                <input
                 type="file"
                 multiple
                 onChange={(e) => setInboxImages([...inboxImages, ...e.target.files])}
                 id="inbox-images"
                 className="file-input"
                />
                <label className="custom-file-label" htmlFor="inbox-images">
                 <i className="fa fa-camera" aria-hidden="true"></i>
                </label>
                <button onClick={sendMessageInbox} className="chat-send-button">
                 Send
                </button>
               </div>
              )}
             </div>
            </div>
           </div>
           <div
            className="tab-pane fade"
            id="notifications"
            role="tabpanel"
            aria-labelledby="notifications-tab"
           >
            {/* Profile content */}
            {/* Notifications content */}
            <div className="notifications-list">
             {alerts.length > 0 ? (
              alerts.map((a, i) => (
               <div key={i} className="notification-item">
                <i className={`fa ${a.icon} notification-icon`} />
                <div className="notification-content">
                 <p className="notification-message">{a.text}</p>
                 <span className="notification-timestamp">{formatLocalTime(a.date)}</span>
                </div>
               </div>
              ))
             ) : (
              <div className="text-center">
               <img src="assets/images/fly.gif" height={100} />
               <p>No notifications here yet...</p>
              </div>
             )}

             {/* Add more notification items as needed */}
            </div>
           </div>
           <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
           >
            {/* Profile content */}
            <div className="profile-card-container">
             <div className="profile-card-header">
              <img
               src={domain + profile.profile_photo}
               alt="Profile Avatar"
               className="profile-card-avatar"
              />
              <div className="profile-card-info">
               <h2 className="profile-card-name">
                {!profile.username ? shortenAddress(profile.wallet) : profile.username}{" "}
                {profile.verified === "yes" && (
                 <i className="fa fa-check-circle" aria-hidden="true" />
                )}
               </h2>
               <p className="profile-card-username">{shortenAddress(profile.wallet)}</p>
               {profileOwner && (
                <button
                 onClick={() => window.open(`/edit-profile`, "_blank", "noopener,noreferrer")}
                 className="btn btn-edit-profile-card"
                >
                 EDIT PROFILE
                </button>
               )}
              </div>
             </div>
             <div className="profile-card-body">
              <div className="profile-card-item">
               <span className="profile-card-label">Username:</span>
               <span className="profile-card-value">{profile.username && profile.username}</span>
              </div>
              {profileOwner && (
               <div className="profile-card-item">
                <span className="profile-card-label">Email:</span>
                <span className="profile-card-value">{user.email}</span>
               </div>
              )}
              <div className="profile-card-item">
               <span className="profile-card-label">Total Fans:</span>
               <span className="profile-card-value">{userFans.length}</span>
              </div>
              <div className="profile-card-item">
               <span className="profile-card-label">Total Posts:</span>
               <span className="profile-card-value">{userVideos.length}</span>
              </div>
              <div className="profile-card-item">
               <span className="profile-card-label">Total Earned:</span>
               <span className="profile-card-value">${formatNumber(profile.total_earned)}</span>
              </div>

              {/* Add more profile details as needed */}
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    )}
    <Footer />
    {/*GIFT modal*/}
    <div
     className="modal fade"
     id={`giftModal`}
     tabIndex={-1}
     role="dialog"
     aria-labelledby={`gModalLabel`}
     aria-hidden="true"
    >
     <div className="modal-dialog modal-md" role="document">
      <div className="modal-content">
       <div className="modal-header">
        <h3 className="modal-title" id={`gModalLabel`}>
         Send Gift
        </h3>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">×</span>
        </button>
       </div>
       <div className="modal-body">
        Your v18plus balance is {formatNumber(user.credits)} ($
        {formatNumber(Math.round(tokenPrice * user.credits))}
        ). Enter the v18plus amount you want to gift to{" "}
        {!profile.username ? shortenAddress(profile.wallet) : profile.username} and hit the PROCEED
        button.
        <div className="my-4">
         <input
          placeholder="Amount"
          className="form-control my-5"
          style={{
           backgroundColor: "#000",
           color: "#fff",
           width: "100%",
           height: "60px",
           border: "#aaa 1px solid",
          }}
          type="number"
          value={creditGift}
          onChange={(e) => setCreditGift(e.target.value)}
         />
         {creditGift && (
          <>
           {formatNumber(creditGift)} v18plus = ${formatNumber(Math.round(tokenPrice * creditGift))}
          </>
         )}
        </div>
       </div>

       <div className="modal-footer">
        {creditGift && (
         <>
          <button type="button" className="btn btn-custom" onClick={sendGift}>
           Proceed
          </button>
         </>
        )}
        <button type="button" className="btn btn-secondary" data-dismiss="modal">
         Close
        </button>
       </div>
      </div>
     </div>
    </div>

    <div
     className="modal fade"
     id="subModal"
     tabIndex={-1}
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
    >
     <div className="modal-dialog modal-md" role="document">
      <div className="modal-content">
       <div className="modal-header">
        <h3 className="modal-title" id="myModalLabel">
         Subscribe to {!profile.username ? shortenAddress(profile.wallet) : profile.username}
         {"'s "} Secret Corner
        </h3>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">×</span>
        </button>
       </div>
       <div className="modal-body">
        {!profile.username ? shortenAddress(profile.wallet) : profile.username} charges{" "}
        <b>
         {formatNumber(profile.content_price)} v18plus ($
         {formatNumber(Math.round(tokenPrice * profile.content_price))}){" "}
        </b>{" "}
        monthly. In turn, you will have unlimited access to all of their private content and also be
        able to chat with them while your subscription is still active.{" "}
        {isConnected && (
         <p>
          Your current v18plus balance is:{" "}
          <b>
           {formatNumber(user.credits)} ($
           {formatNumber(Math.round(tokenPrice * user.credits))})
          </b>
         </p>
        )}
       </div>
       {!isFan && (
        <div className="modal-footer">
         <button type="button" className="btn btn-secondary" data-dismiss="modal">
          No, thanks
         </button>
         {isConnected ? (
          <button
           type="button"
           className="btn btn-custom"
           onClick={() => handleSubscribe(profile.wallet, profile.content_price)}
          >
           Yes, let's do it!
          </button>
         ) : (
          <button disabled={true} type="button" className="btn btn-custom">
           Connect your wallet first.
          </button>
         )}
        </div>
       )}
      </div>
     </div>
    </div>
    {/*Message modal*/}
    <div
     className="modal fade"
     id="msgModal"
     tabIndex={-1}
     role="dialog"
     aria-labelledby="msgModalLabel"
     aria-hidden="true"
    >
     <div className="modal-dialog modal-md" role="document">
      <div className="modal-content">
       <div className="modal-header">
        <h3 className="modal-title" id="msgModalLabel">
         Message {!profile.username ? shortenAddress(profile.wallet) : profile.username}
        </h3>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">×</span>
        </button>
       </div>
       <div className="modal-body">
        <textarea
         style={{
          background: "#111",
          height: "150px",
          color: "#fff",
          width: "100%",
         }}
         placeholder="Type your message here"
         onInput={(e) => setText(e.target.value)}
         value={text}
        ></textarea>
        <input
         type="file"
         multiple
         onChange={(e) => setImages([...images, ...e.target.files])}
         accept="image/*"
         value={images}
        />
       </div>
       {isFan && (
        <div className="modal-footer">
         <button type="button" className="btn btn-secondary" data-dismiss="modal">
          I'm done
         </button>
         {isConnected ? (
          <button type="button" className="btn btn-custom" onClick={sendMessage}>
           Send
          </button>
         ) : (
          <button disabled={true} type="button" className="btn btn-custom">
           Connect your wallet first.
          </button>
         )}
        </div>
       )}
      </div>
     </div>
    </div>
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default Profile;
