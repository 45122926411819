import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 getQueryParam,
 showAlert,
} from "../../utils/helpers";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "./services.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function FaceSwap() {
 const [data, setData] = useState({});
 const [dbImages, setDbImages] = useState([]);
 const [dbImage, setDbImage] = useState("");
 const [selectedImage, setSelectedImage] = useState(null);
 const [swapPhotoPreview, setSwapPhotoPreview] = useState(null);
 const [swapPhoto, setSwapPhoto] = useState(null);
 const [swapVideo, setSwapVideo] = useState(null);
 const [swapVideoPreview, setSwapVideoPreview] = useState(null);
 const [theVideo, setTheVideo] = useState();

 const [swapPhotoPath, setSwapPhotoPath] = useState(null);
 const [swapVideoPath, setSwapVideoPath] = useState(null);
 const videoRef = useRef(null);
 const canvasRef = useRef(null);
 const [frames, setFrames] = useState([]);
 const [selectedFrame, setSelectedFrame] = useState("");
 const [swapAll, setSwapAll] = useState("yes");

 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  loading,
  setLoading,
  settings,
  user,
  debitUser,
  domain,
 } = useContext(Context);

 const mergeImagePaths = (data) => {
  let mergedPaths = [];
  data.forEach((record) => {
   const paths = JSON.parse(record.image_paths);
   mergedPaths = mergedPaths.concat(paths);
  });
  //  console.log("PATH", mergedPaths);
  return mergedPaths;
 };

 const handleImageClick = (image, index) => {
  setSelectedImage(index); // Set the selected image index
  setDbImage(domain + image);
 };

 const handleSwapPhoto = async (e) => {
  const file = e.target.files[0];
  if (file) {
   setLoading(true);
   await handleSwapUpload(file, false);
   setSwapPhoto(file);
   setSwapPhotoPreview(URL.createObjectURL(file));
   setLoading(false);
  }
 };

 const handleVideoChange = async (e) => {
  const file = e.target.files[0];
  if (file && file.type.startsWith("video/")) {
   const videoElement = document.createElement("video");
   videoElement.preload = "metadata";
   videoElement.onloadedmetadata = async function () {
    window.URL.revokeObjectURL(videoElement.src);
    const duration = videoElement.duration;
    if (duration > 59) {
     setSwapVideoPreview(null);
     return showAlert({
      title: "Error!",
      text: "Video must be less than 1 minute.",
      icon: "error",
      confirmButtonText: "Ok",
     });
    } else {
     setLoading(true);
     await handleSwapUpload(file, true);
     setSwapVideoPreview(URL.createObjectURL(file));
     const video = videoRef.current;
     video.src = URL.createObjectURL(file);
     video.onloadeddata = () => {
      extractFrames(video);
     };
     setLoading(false);
    }
   };
   videoElement.src = URL.createObjectURL(file);
  }
 };

 const extractFrames = (video) => {
  const duration = video.duration;
  const interval = duration / 10; // Change this to get more or fewer frames
  const framesArray = [];

  for (let i = 0; i <= 10; i++) {
   setTimeout(() => {
    const currentTime = i * interval;
    video.currentTime = currentTime;
    video.onseeked = () => {
     const canvas = canvasRef.current;
     const context = canvas.getContext("2d");
     context.drawImage(video, 0, 0, canvas.width, canvas.height);
     framesArray.push(canvas.toDataURL());
     if (framesArray.length === 11) {
      setFrames(framesArray);
     }
    };
   }, i * 200); // Adjust timeout to ensure the video has time to seek
  }
 };

 const getUserImages = async (wallet) => {
  // if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_generated_images",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    refresh();
    console.error("Errors:", data.error);
   } else {
    //  const m = mergeImagePaths(data);
    setDbImages(data);
    //  console.log("IMAGES:", m);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getUserImages(address);
 }, [address, isConnected]);

 const refresh = () => {
  setData({});
  setDbImage("");
  setDbImages([]);
  // getUserImages(address);
 };

 const handleSwapUpload = async (swapPhoto, isVideo) => {
  if (!swapPhoto) {
   showAlert({
    title: "Error!",
    text: "Please select an image to upload",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const formData = new FormData();
  formData.append("image", swapPhoto);
  formData.append("action", "upload_swap_face");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });
   if (response.data.path) {
    if (!isVideo) {
     setSwapPhotoPath(response.data.path);
    } else {
     setSwapVideoPath(response.data.path);
    }
   } else {
    showAlert({
     title: "Error!",
     text: response.data.error,
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  } catch (error) {
   console.error("Error uploading the file:", error);
   alert("Error uploading the file.");
  }
 };

 const swapVideoFace = async () => {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (+user.credits < +settings.swap_face_fee) {
   showAlert({
    title: "Error!",
    text: "Insufficient v18plus for swap. Please buy or earn some first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (!swapVideoPath || !dbImage) {
   showAlert({
    title: "Error!",
    text: "Original or target video or  image is missing. Make sure to select these first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const raw = JSON.stringify({
   key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
   init_video: swapVideoPath,
   init_image: dbImage,
   watermark: false,
   webhook: null,
   track_id: null,
  });
  setLoading(true);
  setData({});
  try {
   const response = await fetch("https://modelslab.com/api/v6/deepfake/single_video_swap", {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
    },
    body: raw,
    redirect: "follow",
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   console.log("SWAP", data);
   if (data.status === "processing" && data.future_links) {
    checkVideoAvailability(data);
   } else if (data.status === "success") {
    const d = await debitUser(settings.swap_face_fee);
    if (d) {
     setTheVideo(data.output[0]);
    }
    // setData(data);
    setLoading(false);
   } else if (data.status === "error") {
    console.log("SWAP ERROR", data);

    setLoading(false);
   }
  } catch (error) {
   console.error("Error:", error.message);
   setLoading(false);
  }
 };

 const swapVideoFaceSpecific = async () => {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (+user.credits < +settings.swap_face_fee) {
   showAlert({
    title: "Error!",
    text: "Insufficient v18plus for swap. Please buy or earn some first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (!swapVideoPath || !dbImage) {
   showAlert({
    title: "Error!",
    text: "Original or target video or  image is missing. Make sure to select these first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  if (!selectedFrame) {
   showAlert({
    title: "Error!",
    text: "Please select a frame from the video.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const raw = JSON.stringify({
   key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
   init_video: swapVideoPath,
   init_image: dbImage,
   reference_frame_number: selectedFrame,
   webhook: null,
   track_id: null,
   watermark: false,
  });

  setLoading(true);
  setData({});
  try {
   const response = await fetch("https://modelslab.com/api/v6/deepfake/specific_video_swap", {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
    },
    body: raw,
    redirect: "follow",
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   console.log("SWAP", data);
   if (data.status === "processing" && data.future_links) {
    checkVideoAvailability(data);
   } else if (data.status === "success") {
    const d = await debitUser(settings.swap_face_fee);
    if (d) {
     setTheVideo(data.output[0]);
    }
    // setData(data);
    setLoading(false);
   } else if (data.status === "error") {
    console.log("SWAP ERROR", data);

    setLoading(false);
   }
  } catch (error) {
   console.error("Error:", error.message);
   setLoading(false);
  }
 };

 const handleVideoSwap = () => {
  setData({});
  setTheVideo("");
  if (swapAll === "yes") {
   swapVideoFace();
  } else {
   swapVideoFaceSpecific();
  }
 };

 const pollForResult = async (url) => {
  try {
   const response = await fetch(url, {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   console.log("POLL RESULT", data);
   if (data.status === "processing") {
    setTimeout(() => pollForResult(url), 5000);
   } else if (data.status === "success") {
    const d = await debitUser(settings.swap_face_fee);
    if (d) {
     setData(data);
    }
    setLoading(false);
   }
  } catch (error) {
   console.error("Error:", error.message);
   setLoading(false);
  }
 };

 const saveVideo = async () => {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  if (!theVideo) {
   showAlert({
    title: "Error!",
    text: "Video not found.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  setLoading(true);

  const formData = new FormData();

  formData.append("wallet", address);
  formData.append("video", theVideo);

  formData.append("action", "save_ai_video");

  try {
   const response = await fetch(apiURL, {
    method: "POST",
    body: formData,
   });

   const datas = await response.json();

   if (datas.success) {
    showAlert({
     title: "Success!",
     text: datas.message,
     icon: "success",
     confirmButtonText: "Sounds good",
    });
   } else {
    showAlert({
     title: "Error!",
     text: datas.message,
     icon: "error",
     confirmButtonText: "Ok",
    });
    console.log("Error:", datas.message);
   }
  } catch (error) {
   showAlert({
    title: "Error!",
    text: "An unexpected error occurred. Please try again later.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   console.error("Error:", error);
  } finally {
   setLoading(false);
  }
 };

 const swapFace = async () => {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (+user.credits < +settings.swap_face_fee) {
   showAlert({
    title: "Error!",
    text: "Insufficient v18plus for swap. Please buy or earn some first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (!swapPhotoPath || !dbImage) {
   showAlert({
    title: "Error!",
    text: "Original or target image is missing. Make sure to select these photos first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const raw = JSON.stringify({
   key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
   target_image: swapPhotoPath,
   init_image: dbImage,
   watermark: false,
   webhook: null,
   track_id: null,
  });
  setLoading(true);
  setData({});
  try {
   const response = await fetch("https://modelslab.com/api/v6/deepfake/multiple_face_swap", {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
    },
    body: raw,
    redirect: "follow",
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   console.log("SWAP", data);
   if (data.status === "processing" && data.future_links) {
    checkImageAvailability(data);
   } else if (data.status === "success") {
    const d = await debitUser(settings.swap_face_fee);
    if (d) {
     setData(data);
    }

    setLoading(false);
   } else if (data.status === "error") {
    console.log("SWAP ERROR", data);

    setLoading(false);
   }
  } catch (error) {
   console.error("Error:", error.message);
   setLoading(false);
  }
 };

 const checkImageAvailability = async (data) => {
  setLoading(true);
  const interval = 5000;

  const poll = setInterval(async () => {
   try {
    const response = await axios.get(data.future_links[0], {
     validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
     },
    });

    if (response.status === 200) {
     clearInterval(poll);
     const d = await debitUser(settings.swap_face_fee);
     if (d) {
      data.output[0] = data.future_links[0];
      setData(data);
      console.log("SWAP MOD", data);
     }
     setLoading(false);
    } else {
     console.log("Image not available yet. Retrying...");
    }
   } catch (error) {
    console.log("Error fetching the image:", error);
   }
  }, interval);
 };

 const checkVideoAvailability = async (data) => {
  setLoading(true);
  const interval = 5000;

  const poll = setInterval(async () => {
   try {
    const response = await axios.get(data.future_links[0], {
     validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
     },
    });

    if (response.status === 200) {
     clearInterval(poll);
     const d = await debitUser(settings.swap_face_fee);
     if (d) {
      data.output[0] = data.future_links[0];
      setTheVideo(data.future_links[0]);
      console.log("SWAP MOD", data);
     }
     setLoading(false);
    } else {
     console.log("Video not available yet. Retrying...");
    }
   } catch (error) {
    console.log("Error fetching the image:", error);
   }
  }, interval);
 };

 const testVid = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
   key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
   model_id: "zeroscope",
   prompt: "full HD video of a woman with blonde hair touching her big breasts.",
   negative_prompt: "low quality",
   height: 320,
   width: 576,
   num_frames: 20,
   fps: 2,
   num_inference_steps: 20,
   guidance_scale: 7,
   upscale_height: 640,
   upscale_width: 1024,
   upscale_strength: 0.6,
   upscale_guidance_scale: 12,
   upscale_num_inference_steps: 20,
   output_type: "mp4",
   webhook: null,
   track_id: null,
  });

  const requestOptions = {
   method: "POST",
   headers: myHeaders,
   body: raw,
   redirect: "follow",
  };

  fetch("https://modelslab.com/api/v6/video/text2video", requestOptions)
   .then((response) => response.json())
   .then((result) => console.log(result))
   .catch((error) => console.log("error", error));
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: "url(assets/images/nft-bg4.jpeg)",
         backgroundAttachment: "fixed",
        }}
       >
        <div className="overlay-top-header" />
        <div className="content container" style={{ paddingBottom: "30px" }}>
         <h1 style={{ fontWeight: 700 }}>LET'S FACE-SWAP</h1>
         <div className="description" style={{ color: "#fff" }}>
          <b>Tips:</b>
          <br />
          To get best on your Ai face swap, please ensure the quality of the video you want to
          reface has a high resolution with clear faces.
         </div>
        </div>
       </div>
      </div>
      <div className="site-content layout-1">
       <div className="container">
        {/* Nav tabs */}
        <ul className="nav nav-tabs mt-4" role="tablist">
         <li className="nav-item">
          <a className="nav-link active" data-toggle="tab" href="#images">
           Images
          </a>
         </li>
         <li className="nav-item">
          <a className="nav-link" data-toggle="tab" href="#videos">
           Videos
          </a>
         </li>
        </ul>
        {/* Tab content */}
        <div className="tab-content">
         <div id="images" className="container tab-pane active">
          <br />
          <h2>HOW IT WORKS:</h2>
          <ul className="stylish-list">
           <li>
            Upload the image containing the face(s) you want to replace or paste the link to the
            image in the field provided below.
           </li>
           <li>
            Pick a replacement face from your Ai models or create a new one. The number of faces in
            both images must be equal and faces should be frontal and very clear for best results.
           </li>
           <li>Press the START SWAP button and wait for the magic.</li>
          </ul>
          <div className="form-container">
           <>
            <div className="row">
             <div className="col-md-5 text-center">
              <div className="form-group">
               <label htmlFor="oFace">
                <img src={swapPhotoPreview || "assets/images/sil.png"} width={200} />
               </label>
               <div className="custom-file">
                <input
                 accept="image/*"
                 type="file"
                 className="custom-file-input"
                 id="oFace"
                 required
                 onChange={handleSwapPhoto}
                />
                <label className="custom-file-label" htmlFor="oFace">
                 Choose original face
                </label>
                <div>
                 <input
                  style={{
                   height: "35px",
                  }}
                  className="w-100  my-3"
                  placeholder="Or paste image URL here..."
                  type="text"
                  value={swapPhotoPath || ""}
                  onChange={(e) => setSwapPhotoPath(e.target.value)}
                 />
                </div>
               </div>
              </div>
             </div>
             <div className="col-md-2 d-flex align-items-center justify-content-center">
              <i className="fa fa-long-arrow-right gradient-icon" aria-hidden="true" />
             </div>
             <div className="col-md-5 text-center">
              <div>
               <img
                src={`${(dbImage?.trim() || "") !== "" ? dbImage : "assets/images/sil.png"}`}
                width={200}
               />
              </div>
              <div className="custom-file">
               <label className="custom-file-label">
                Choose replacement face from your models below
               </label>
              </div>
             </div>

             <div className="col-md-12 text-center">
              <h3>
               Choose an image from your Ai models you wish to swap to or{" "}
               <a className="text-danger" href="/porn-face" target="_blank">
                create a new model
               </a>
              </h3>
             </div>
             <div className="col-md-12 text-center">
              <Swiper
               navigation={true}
               modules={[Pagination, Autoplay, Navigation]}
               // slidesPerView={3}
               loop={true}
               breakpoints={{
                640: {
                 slidesPerView: 2,
                 spaceBetween: 20,
                },
                768: {
                 slidesPerView: 4,
                 spaceBetween: 30,
                },
                1024: {
                 slidesPerView: 6,
                 spaceBetween: 30,
                },
               }}
               className="my-5"
              >
               {dbImages.length > 0 &&
                dbImages.map((image, index) => (
                 <SwiperSlide key={index}>
                  <div
                   className={`image-container ${selectedImage === index ? "selected" : ""}`}
                   onClick={() => handleImageClick(image.image_paths, index)}
                  >
                   <img
                    src={domain + image.image_paths}
                    alt={`Slide ${index}`}
                    className={`gallery-image ${selectedImage === index ? "selected" : ""}`}
                   />
                  </div>
                 </SwiperSlide>
                ))}
              </Swiper>
              {dbImages.length === 0 && (
               <div className="text-center">
                <img src="assets/images/fly.gif" height={100} alt="No images" />
                <p>
                 No Ai models here yet...{" "}
                 <a className="text-danger" href="/porn-face" target="_blank">
                  create a new model first
                 </a>
                </p>
               </div>
              )}
             </div>

             <div className="col-md-12 my-3">
              <div className="swap-result">
               {Object.keys(data).length > 0 ? (
                <img src={data.output[0]} height={300} />
               ) : (
                <>
                 <i className="fa fa-spinner mr-2" aria-hidden="true" />
                 <span>Results will show up here.....</span>
                </>
               )}
              </div>
             </div>
             <div
              className="w-100 text-center p-3 my-3 rounded"
              style={{
               backgroundColor: "#ddd",
               border: "1px red solid",
              }}
             >
              <i className="text-warning fa fa-info-circle" aria-hidden="true"></i> You will be
              charged{" "}
              <strong className="text-danger">{formatNumber(settings.swap_face_fee)}</strong>{" "}
              platform v18plus for this service.
             </div>
             <div className="col-md-12">
              <button className="btn btn-custom btn-block" onClick={swapFace}>
               Start the Swap
              </button>
             </div>
            </div>
           </>
          </div>
         </div>
         <div id="videos" className="container tab-pane fade">
          <br />
          <h2>HOW IT WORKS:</h2>
          <ul className="stylish-list">
           <li>
            Upload the video containing the face(s) you want to replace or paste the link to the
            video in the field provided below. Video must be less than 1 minute.
           </li>
           <li>
            Pick a replacement face from your Ai models or create a new one. The number of faces in
            both image and video must be equal and faces should be frontal and very clear for best
            results.
           </li>
           <li>Press the START SWAP button and wait for the magic.</li>
          </ul>
          <div className="form-container">
           <>
            <div className="custom-radio-group my-2">
             <div className="form-check form-check-inline mr-2">
              <input
               className="form-check-input custom-radio-input"
               type="radio"
               id="swapYes"
               name="swapAll"
               value="yes"
               checked={swapAll === "yes"}
               onChange={(e) => setSwapAll(e.target.value)}
              />
              <label className="form-check-label custom-radio-label" htmlFor="swapYes">
               Swap detected faces in video
              </label>
             </div>{" "}
             <div className="form-check form-check-inline mt-2">
              <input
               className="form-check-input custom-radio-input"
               type="radio"
               id="swapNo"
               name="swapAll"
               value="no"
               onChange={(e) => setSwapAll(e.target.value)}
               checked={swapAll === "no"}
              />
              <label className="form-check-label custom-radio-label" htmlFor="swapNo">
               Swap specific face(s) in video
              </label>
             </div>
            </div>

            <div className="row">
             <div className="col-md-5 text-center">
              <div className="form-group">
               <label htmlFor="swapVideo">
                <video
                 ref={videoRef}
                 className="img-fluid mt-3"
                 style={{
                  maxWidth: "100%",
                  height: "300px",
                 }}
                 controls
                 autoplay={false}
                />
               </label>

               <div className="custom-file">
                <input
                 accept="video/mp4"
                 type="file"
                 className="custom-file-input"
                 id="swapVideo"
                 required
                 onChange={handleVideoChange}
                />
                <label className="custom-file-label" htmlFor="swapVideo">
                 Choose original photo
                </label>
                {swapAll === "yes" && (
                 <div>
                  <input
                   style={{
                    height: "35px",
                   }}
                   className="w-100  my-3"
                   placeholder="Or paste image URL here..."
                   type="text"
                   value={swapVideoPath || ""}
                   onChange={(e) => setSwapVideoPath(e.target.value)}
                  />
                 </div>
                )}
               </div>
              </div>
             </div>
             <div className=" col-md-2 d-flex align-items-center justify-content-center">
              <i className="fa fa-long-arrow-right gradient-icon" aria-hidden="true" />
             </div>
             <div className="col-md-5 text-center">
              <div className="form-group">
               <label>
                <img src={dbImage || "assets/images/sil.png"} width={200} />
               </label>
               <div className="custom-file">
                <label className="custom-file-label">
                 Choose replacement face from your models below
                </label>
               </div>
              </div>
             </div>
             <div className="col-md-12 text-center my-5">
              {swapAll === "no" && frames.length > 0 && (
               <h3 className="mb-3">Click the face in the video to swap </h3>
              )}
              <canvas ref={canvasRef} width="320" height="180" style={{ display: "none" }} />
              <div
               style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
               }}
              >
               {swapAll === "no" &&
                frames.length > 0 &&
                frames.map((frame, index) => (
                 <img
                  key={index}
                  src={frame}
                  alt={`Frame ${index}`}
                  onClick={() => setSelectedFrame(index)}
                  style={{
                   cursor: "pointer",
                   width: "100px",
                   height: "auto",
                  }}
                  className={`frame-image ${selectedFrame === index ? "selected" : ""}`}
                 />
                ))}
              </div>
             </div>

             <div className="col-md-12 text-center">
              <h3>
               Choose an image from your Ai models you wish to swap to or{" "}
               <a className="text-danger" href="/porn-face" target="_blank">
                create a new model
               </a>
              </h3>
             </div>
             <div className="col-md-12 text-center">
              <Swiper
               navigation={true}
               modules={[Pagination, Autoplay, Navigation]}
               // slidesPerView={3}
               loop={true}
               breakpoints={{
                640: {
                 slidesPerView: 2,
                 spaceBetween: 20,
                },
                768: {
                 slidesPerView: 4,
                 spaceBetween: 30,
                },
                1024: {
                 slidesPerView: 6,
                 spaceBetween: 30,
                },
               }}
               className="my-5"
              >
               {dbImages.length > 0 &&
                dbImages.map((image, index) => (
                 <SwiperSlide key={index}>
                  <div
                   className={`image-container ${selectedImage === index ? "selected" : ""}`}
                   onClick={() => handleImageClick(image.image_paths, index)}
                  >
                   <img
                    src={domain + image.image_paths}
                    alt={`Slide ${index}`}
                    className={`gallery-image ${selectedImage === index ? "selected" : ""}`}
                   />
                  </div>
                 </SwiperSlide>
                ))}
              </Swiper>
              {dbImages.length === 0 && (
               <div className="text-center">
                <img src="assets/images/fly.gif" height={100} alt="No images" />
                <p>
                 No Ai models here yet...{" "}
                 <a className="text-warning" href="/porn-face" target="_blank">
                  create a new model first
                 </a>
                </p>
               </div>
              )}
             </div>

             <div className="col-md-12 my-3">
              <div className="swap-result">
               {theVideo && (
                <>
                 <div>
                  <a href={theVideo} className="btn btn-success mr-2" target="_blank">
                   {" "}
                   <i className="fa fa-download mr-2" aria-hidden="true" /> WATCH VIDEO
                  </a>
                  <button className="btn btn-primary" onClick={saveVideo}>
                   {" "}
                   <i className="fa fa-cloud mr-2" aria-hidden="true" /> SAVE VIDEO
                  </button>

                  <div className="d-block">
                   Video not found? Wait a few seconds and press the button again.
                  </div>
                 </div>
                </>
               )}{" "}
               {!theVideo && (
                <>
                 <i className="fa fa-spinner mr-2" aria-hidden="true" />
                 <span>Waiting for result. It may take a while.....</span>
                </>
               )}
              </div>
              <div
               className="w-100 text-center p-3 my-3 rounded"
               style={{
                backgroundColor: "#ddd",
                border: "1px red solid",
               }}
              >
               <i className="text-warning fa fa-info-circle" aria-hidden="true"></i> You will be
               charged{" "}
               <strong className="text-danger">{formatNumber(settings.swap_face_fee)}</strong>{" "}
               platform v18plus for this service.
              </div>
             </div>
             <div className="col-md-12">
              <button className="btn btn-custom btn-block" onClick={handleVideoSwap}>
               Start the Swap
              </button>
             </div>
            </div>
           </>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>

   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default FaceSwap;
