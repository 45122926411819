import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Context } from "../context/contex";
import { NftContract } from "../utils/readContract";
import nftABI from "../utils/nft.json";
import Pagination from "../components/Pagination";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { BrowserProvider, Contract, formatUnits, parseUnits, JsonRpcProvider } from "ethers";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 getQueryParam,
 showAlert,
 removeDecimals,
} from "../utils/helpers";
import axios from "axios";

import "../pages/services/services.css";

const nftContract = "0xEa3d89683BEEb93D600Ba42128f409dA899DfFe5";

function Admin() {
 const [name, setName] = useState("");
 const [users, setUsers] = useState([]);
 const [symbol, setSymbol] = useState("");
 const [stats, setStats] = useState([]);
 const [videos, setVideos] = useState([]);
 const [quantity, setQuantity] = useState(1);
 const [query, setQuery] = useState("");
 const [pquery, setPQuery] = useState("");
 const [vquery, setVQuery] = useState("");

 const sidebarRef = useRef(null);

 const [isLoading, setIsLoading] = useState(false);
 const [results, setResults] = useState([]);
 const [vresults, setVResults] = useState([]);
 const [presults, setPResults] = useState([]);

 const [products, setProducts] = useState([]);
 const [title, setTitle] = useState("");
 const [gender, setGender] = useState("");
 const [image, setImage] = useState(null);
 const [price, setPrice] = useState("");
 const [link, setLink] = useState("");
 const [ratio, setRatio] = useState("1:1");
 const [hideSave, setHideSave] = useState(false);
 const [imgStyle, setImgStyle] = useState("photorealism");
 const [data, setData] = useState({});
 const [menu, setMenu] = useState("home");
 const [dbImage, setDbImage] = useState({});
 const [supply, setSupply] = useState("");
 const [baseUrl, setBaseUrl] = useState("");
 const [paginationU, setPaginationU] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });

 const [paginationV, setPaginationV] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [paginationP, setPaginationP] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });

 const [setting, setSetting] = useState({
  admin_email: "",
  project_id: "",
  porn_face_fee: "",
  swap_face_fee: "",
  token: "",
  token_decimal: "",
  current_daily_mint: "",
 });

 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  loading,
  setLoading,
  settings,
  user,
  debitUser,
  domain,
  tokenPrice,
  getSettings,
 } = useContext(Context);

 const getProducts = async (page = 1, limit = 10) => {
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_products", page: page, limit: limit }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setProducts(data.records);
    setPaginationP(data.pagination);

    console.log("All products:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getProducts(1);
 }, []);

 const postProduct = async () => {
  if (!isConnected || user.is_admin !== "yes") {
   showAlert({
    title: "Error!",
    text: "Please connect to an admin wallet",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  if (!title || !gender || !link || image == null) {
   showAlert({
    title: "Error!",
    text: "Please complete all required fields.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const formData = new FormData();
  formData.append("image", image);
  formData.append("title", title);
  formData.append("gender", gender);
  formData.append("price", price ? price : 0);
  formData.append("link", link);

  formData.append("action", "post_product");
  setLoading(true);

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });
   if (response.data.success) {
    showAlert({
     title: "SUCCESS!",
     text: "Product uploaded successfully.",
     icon: "success",
     confirmButtonText: "Ok",
    });
    await getProducts(1);
   } else {
    showAlert({
     title: "Error!",
     text: response.data.error,
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  } catch (error) {
   console.error("Error uploading the file:", error);
   //  alert("Error uploading the file.");
  }
  setLoading(false);
 };

 useEffect(() => {
  setSetting({
   admin_email: settings.admin_email,
   project_id: settings.project_id,
   porn_face_fee: settings.porn_face_fee,
   swap_face_fee: settings.swap_face_fee,
   token: settings.token,
   token_decimal: settings.token_decimal,
   current_daily_mint: settings.current_daily_mint,
  });
 }, [settings]);

 const changeSettings = (e) => {
  setSetting({ ...setting, [e.target.name]: e.target.value });
 };

 const submitSettings = async (e) => {
  e.preventDefault();
  setLoading(true);

  try {
   const response = await axios.post(
    apiURL,
    new URLSearchParams({
     action: "update_settings",
     c_a: address,
     ...setting,
    }),
    {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    }
   );

   if (response.data.success) {
    await getSettings();
    showAlert({
     title: "Success!",
     text: "Settings updated successfully!",
     icon: "success",
     confirmButtonText: "OK",
    });
   } else {
    showAlert({
     title: "Error!",
     text: "Error updating settings: " + response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error updating settings:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while updating settings.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(false);
 };

 const close = () => {
  const buttons = document.querySelectorAll(".close-btn");
  buttons.forEach((button) => button.click());
 };

 const getVideos = async (page = 1, limit = 12) => {
  setLoading(true);

  try {
   const response = await axios.post(
    apiURL,
    new URLSearchParams({
     action: "get_all_videos",
     page: page,
     limit: limit,
    }),
    {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    }
   );

   const data = response.data;

   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setVideos(data.records);
    setPaginationV(data.pagination);

    console.log("RECORDS:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getVideos(1);
 }, []);

 const MySwal = withReactContent(Swal);

 const handleDelete = async (wallet) => {
  const result = await MySwal.fire({
   title: "Are you sure?",
   text: "You won't be able to revert this action and all user records will be deleted!",
   icon: "warning",
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "Cancel",
  });

  if (result.isConfirmed) {
   // Execute the delete function
   deleteUser(wallet);
  }
 };

 const doDelete = async (id) => {
  const result = await MySwal.fire({
   title: "Are you sure?",
   text: "You won't be able to revert this action and the video will be deleted!",
   icon: "warning",
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "Cancel",
  });

  if (result.isConfirmed) {
   // Execute the delete function
   setLoading(true);

   try {
    const details = {
     action: "delete_video",
     id: id,
    };

    const response = await fetch(apiURL, {
     method: "POST",
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
     body: new URLSearchParams(details),
    });

    if (!response.ok) {
     throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    if (!data.success) {
     console.error("Errors:", data.error);
     showAlert({
      title: "Error!",
      text: data.message,
      icon: "error",
      confirmButtonText: "OK",
     });
    } else {
     close();
     await getVideos(1);
     await getStats();
     showAlert({
      title: "Success!",
      text: data.message,
      icon: "success",
      confirmButtonText: "OK",
     });
    }
   } catch (error) {
    console.error("Error:", error.message);
   }
   setLoading(false);
  }
 };

 const deleteProduct = async (id) => {
  const result = await MySwal.fire({
   title: "Are you sure?",
   text: "You won't be able to revert this action and the product will be deleted!",
   icon: "warning",
   showCancelButton: true,
   confirmButtonText: "Yes, delete it!",
   cancelButtonText: "Cancel",
  });

  if (result.isConfirmed) {
   // Execute the delete function
   setLoading(true);

   try {
    const details = {
     action: "delete_product",
     id: id,
    };

    const response = await fetch(apiURL, {
     method: "POST",
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
     body: new URLSearchParams(details),
    });

    if (!response.ok) {
     throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    if (!data.success) {
     console.error("Errors:", data.error);
     showAlert({
      title: "Error!",
      text: data.message,
      icon: "error",
      confirmButtonText: "OK",
     });
    } else {
     await getProducts(1);
     setPQuery("");
     showAlert({
      title: "Success!",
      text: data.message,
      icon: "success",
      confirmButtonText: "OK",
     });
    }
   } catch (error) {
    console.error("Error:", error.message);
   }
   setLoading(false);
  }
 };

 const deleteUser = async (wallet) => {
  setLoading(true);

  try {
   const details = {
    action: "delete_user",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Errors:", data.error);
    showAlert({
     title: "Error!",
     text: data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   } else {
    await getUsers(1);
    await getStats();
    showAlert({
     title: "Success!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 const updateKYC = async (wallet, value) => {
  setLoading(true);

  try {
   const details = {
    action: "update_user_kyc",
    value: value,
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Errors:", data.error);
    showAlert({
     title: "Error!",
     text: data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   } else {
    await getUsers(1);
    showAlert({
     title: "Success!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  if (query.length > 0) {
   const fetchData = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("query", query);
    //formData.append("type", type);

    formData.append("action", "search_users");
    try {
     const response = await axios.post(apiURL, formData, {
      headers: {
       "Content-Type": "application/x-www-form-urlencoded",
      },
     });
     setResults(response.data);
    } catch (error) {
     console.error("Error fetching search results", error);
    }
    setIsLoading(false);
   };

   const debounceTimeout = setTimeout(() => {
    fetchData();
   }, 300); // Adjust the debounce delay as needed

   return () => clearTimeout(debounceTimeout);
  } else {
   setResults([]);
  }
 }, [query]);

 useEffect(() => {
  if (vquery.length > 0) {
   const fetchData = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("query", vquery);
    //formData.append("type", type);

    formData.append("action", "search_admin_videos");
    try {
     const response = await axios.post(apiURL, formData, {
      headers: {
       "Content-Type": "application/x-www-form-urlencoded",
      },
     });
     setVResults(response.data);
    } catch (error) {
     console.error("Error fetching search results", error);
    }
    setIsLoading(false);
   };

   const debounceTimeout = setTimeout(() => {
    fetchData();
   }, 300); // Adjust the debounce delay as needed

   return () => clearTimeout(debounceTimeout);
  } else {
   setVResults([]);
  }
 }, [vquery]);

 const getUsers = async (page = 1, limit = 10) => {
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "get_all_users",
     page: page,
     limit: limit,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUsers(data.records);
    setPaginationU(data.pagination);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getUsers(1);
 }, []);

 const getSupply = async () => {
  try {
   const contract = await NftContract();

   const res = await contract.totalSupply();
   console.log("SSS", res.toString());

   setSupply(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getSupply();
 }, []);

 const getName = async () => {
  try {
   const contract = await NftContract();

   const res = await contract.name();
   // console.log("SSS", res.toString());

   setName(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getName();
 }, []);

 const getSymbol = async () => {
  try {
   const contract = await NftContract();

   const res = await contract.symbol();
   //  console.log("SSS", res.toString());

   setSymbol(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getSymbol();
 }, []);

 const getBaseUrl = async () => {
  try {
   const contract = await NftContract();

   const res = await contract.baseURI();
   console.log("SSS", res.toString());

   setBaseUrl(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getBaseUrl();
 }, []);

 const getStats = async () => {
  // if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_overview_stats",
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Errors:", data.error);
   } else {
    setStats(data.data);
    console.log("STATS:", data.data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getStats();
 }, []);

 const handleToggle = () => {
  if (sidebarRef.current) {
   sidebarRef.current.classList.toggle("show");
  }
 };

 useEffect(() => {
  if (pquery.length > 0) {
   const fetchData = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("query", pquery);
    //formData.append("type", type);

    formData.append("action", "search_products");
    try {
     const response = await axios.post(apiURL, formData, {
      headers: {
       "Content-Type": "application/x-www-form-urlencoded",
      },
     });
     setPResults(response.data);
    } catch (error) {
     console.error("Error fetching search results", error);
    }
    setIsLoading(false);
   };

   const debounceTimeout = setTimeout(() => {
    fetchData();
   }, 300); // Adjust the debounce delay as needed

   return () => clearTimeout(debounceTimeout);
  } else {
   setPResults([]);
  }
 }, [pquery]);

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      {isConnected && user.is_admin === "yes" && (
       <div className="site-content layout-1">
        <div className="container-fluid">
         <div className="d-flex">
          <div className="sidebar-admin" ref={sidebarRef}>
           <div className="text-right">
            <button className="btn btn-danger d-md-none mb-3" onClick={handleToggle}>
             <i style={{ fontSize: "30px" }} className="fa fa-times-circle" aria-hidden="true" />
            </button>
           </div>
           <div className="list-group list-group-flush">
            <h3 className="p-4 text-capitalize text-white">ADMIN PANEL</h3>
            <button
             className={`list-group-item list-group-item-action ${menu === "home" && "active"}`}
             onClick={() => setMenu("home")}
            >
             <i className="fa fa-home" aria-hidden="true"></i>
             <span>Dashboard</span>
            </button>
            <button
             className={`list-group-item list-group-item-action ${menu === "users" && "active"}`}
             onClick={() => setMenu("users")}
            >
             <i className="fa fa-users" aria-hidden="true"></i>
             <span>Users</span>
            </button>
            <button
             className={`list-group-item list-group-item-action ${menu === "videos" && "active"}`}
             onClick={() => setMenu("videos")}
            >
             <i className="fa fa-play-circle-o" aria-hidden="true"></i>
             <span>Videos</span>
            </button>
            <button
             className={`list-group-item list-group-item-action ${menu === "products" && "active"}`}
             onClick={() => setMenu("products")}
            >
             <i className="fa fa-cart-arrow-down" aria-hidden="true"></i>
             <span>Products</span>
            </button>
            <button
             className={`list-group-item list-group-item-action ${menu === "settings" && "active"}`}
             onClick={() => setMenu("settings")}
            >
             <i className="fa fa-cog" aria-hidden="true"></i>
             <span>Settings</span>
            </button>
           </div>
          </div>
          <div className="main-content flex-grow-1 w-100">
           <button className="btn btn-danger d-md-none mb-3" onClick={handleToggle}>
            Show menu
           </button>
           <div className="container-fluid">
            {menu === "home" && (
             <div className="row">
              <div className="col-md-4">
               <div className="overview-box purple">
                <div className="icon">
                 <i className="fa fa-user"></i>
                </div>
                <div className="details">
                 <h4>Total Users</h4>
                 <span>{removeDecimals(stats.total_users)}</span>
                </div>
               </div>
              </div>
              <div className="col-md-4">
               <div className="overview-box blue">
                <div className="icon">
                 <i className="fa fa-users"></i>
                </div>
                <div className="details">
                 <h4>Total Creators</h4>
                 <span>{removeDecimals(stats.total_creators)}</span>
                </div>
               </div>
              </div>
              <div className="col-md-4">
               <div className="overview-box green">
                <div className="icon">
                 <i className="fa fa-money"></i>
                </div>
                <div className="details">
                 <h4>Total users v18plus</h4>
                 <span>{removeDecimals(stats.total_credits)}</span>
                </div>
               </div>
              </div>
              <div className="col-md-4">
               <div className="overview-box light-green">
                <div className="icon">
                 <i className="fa fa-video-camera"></i>
                </div>
                <div className="details">
                 <h4>Total videos</h4>
                 <span>{removeDecimals(stats.total_video_posts)}</span>
                </div>
               </div>
              </div>
              <div className="col-md-4">
               <div className="overview-box purple">
                <div className="icon">
                 <i className="fa fa-eye"></i>
                </div>
                <div className="details">
                 <h4>Viewers earnings</h4>
                 <span>{removeDecimals(stats.total_v_distributions)}</span>
                </div>
               </div>
              </div>
              <div className="col-md-4">
               <div className="overview-box blue">
                <div className="icon">
                 <i className="fa fa-user-plus"></i>
                </div>
                <div className="details">
                 <h4>Creators earnings</h4>
                 <span>{removeDecimals(stats.total_c_distributions)}</span>
                </div>
               </div>
              </div>
              <div className="col-md-4">
               <div className="overview-box purple">
                <div className="icon">
                 <i className="fa fa-image"></i>
                </div>
                <div className="details">
                 <h4>{symbol} Total supply</h4>
                 <span>{removeDecimals(supply)}</span>
                </div>
               </div>
              </div>
             </div>
            )}
            {menu === "users" && (
             <>
              <div className="row">
               <div className="col-md-2" />
               <div className="col-md-8">
                <input
                 placeholder="Search users by wallet or username"
                 className="form-control my-5"
                 style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  width: "100%",
                  height: "60px",
                  border: "#aaa 1px solid",
                 }}
                 type="search"
                 value={query}
                 onChange={(e) => setQuery(e.target.value)}
                />
                {!isLoading && (
                 <div>
                  {results.length > 0 &&
                   results.map((p, i) => (
                    <>
                     <div className="card nft-card">
                      <div className="card-body">
                       <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                        <img
                         src={domain + p.profile_photo}
                         className="card-img-topa"
                         alt="Avatar"
                         width={50}
                         style={{ borderRadius: "50%" }}
                        />
                        <div>
                         <h5 className="card-title">
                          <a
                           href={`profile?user=${!p.username ? p.wallet : p.username}`}
                           target="_blank"
                          >
                           {!p.username ? shortenAddress(p.wallet) : p.username}
                          </a>{" "}
                          {p.verified === "yes" && (
                           <i className="fa fa-check-circle text-success" aria-hidden="true" />
                          )}
                         </h5>

                         <p className="price">
                          {formatNumber(p.credits)} v18plus{" "}
                          <span className="text-primary">
                           ($
                           {formatNumber(Math.round(tokenPrice * p.credits))})
                          </span>
                         </p>
                         <p>KYC: {p.verified}</p>
                         <p>Creator: {p.is_creator}</p>
                         <div
                          className="text-center mt-1 d-flex"
                          style={{
                           gap: 20,
                           justifyContent: "center",
                          }}
                         >
                          <a
                           href={`profile?user=${!p.username ? p.wallet : p.username}`}
                           target="_blank"
                          >
                           <i class="fa fa-eye text-success" aria-hidden="true"></i>
                          </a>
                          <button data-toggle="modal" data-target={`#SeditModal-${i}`}>
                           <i class="fa fa-pencil-square-o text-primary" aria-hidden="true"></i>
                          </button>
                          <button onClick={() => handleDelete(p.wallet)}>
                           <i class="fa fa-trash-o text-danger" aria-hidden="true"></i>
                          </button>
                         </div>
                        </div>
                       </div>
                      </div>
                     </div>
                     {/*EDIT modal*/}
                     <div
                      className="modal fade"
                      id={`SeditModal-${i}`}
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby={`sModalLabel-${i}`}
                      aria-hidden="true"
                     >
                      <div className="modal-dialog modal-lg" role="document">
                       <div className="modal-content">
                        <div className="modal-header">
                         <h3 className="modal-title" id={`sModalLabel-${i}`}>
                          Edit {!p.username ? shortenAddress(p.wallet) : p.username}
                         </h3>
                         <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                         >
                          <span aria-hidden="true">×</span>
                         </button>
                        </div>
                        <div className="modal-body">
                         {p.verified !== "no" && (
                          <div className="row">
                           <div className="col-md-12">ID DOCUMENTS</div>
                           <div className="col-md-3">
                            <img src={domain + p.front} height={150} />
                            <p>Front</p>
                           </div>
                           <div className="col-md-3">
                            <img src={domain + p.back} height={150} />
                            <p>Back</p>
                           </div>{" "}
                           <div className="col-md-3">
                            <img src={domain + p.self} height={150} />
                            <p>Self</p>
                           </div>
                           <div className="col-md-6">
                            <div className="form-group">
                             <label htmlFor="username">First name</label>
                             <input
                              type="text"
                              className="form-control"
                              value={p.first_name || ""}
                              readOnly
                             />
                            </div>
                           </div>
                           <div className="col-md-6">
                            <div className="form-group">
                             <label htmlFor="username">Last name</label>
                             <input
                              type="text"
                              className="form-control"
                              value={p.surname || ""}
                              readOnly
                             />
                            </div>
                           </div>
                           <div className="col-md-6">
                            <div className="form-group">
                             <label>Gender</label>
                             <input
                              type="text"
                              className="form-control"
                              value={p.gender || ""}
                              readOnly
                             />
                            </div>
                           </div>
                           <div className="col-md-6">
                            <div className="form-group">
                             <label>Date of birth</label>
                             <input
                              type="text"
                              className="form-control"
                              value={p.dob || ""}
                              readOnly
                             />
                            </div>
                           </div>
                           <div className="col-md-6">
                            <div className="form-group">
                             <label>Country</label>
                             <input
                              type="text"
                              className="form-control"
                              value={p.country || ""}
                              readOnly
                             />
                            </div>
                           </div>
                           <div className="col-md-6">
                            <div className="form-group">
                             <label>ID type</label>
                             <input
                              type="text"
                              className="form-control"
                              value={p.id_type || ""}
                              readOnly
                             />
                            </div>
                           </div>
                           <div className="col-md-6">
                            <div className="form-group">
                             <label>ID NUMBER</label>
                             <input
                              type="text"
                              className="form-control"
                              value={p.id_number || ""}
                              readOnly
                             />
                            </div>
                           </div>
                          </div>
                         )}
                         <div className="row">
                          <div className="col-md-6">
                           <div className="form-group">
                            <label htmlFor="username">Wallet</label>
                            <input
                             type="text"
                             className="form-control"
                             value={p.wallet || ""}
                             readOnly
                            />
                           </div>
                          </div>
                          <div className="col-md-6">
                           <div className="form-group">
                            <label htmlFor="username">Email</label>
                            <input
                             type="text"
                             className="form-control"
                             value={p.email || ""}
                             readOnly
                            />
                           </div>
                          </div>
                         </div>
                        </div>

                        <div className="modal-footer">
                         {p.verified === "pending" && (
                          <>
                           <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => updateKYC(p.wallet, "yes")}
                           >
                            Approve KYC
                           </button>
                           <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => updateKYC(p.wallet, "no")}
                           >
                            Reject KYC
                           </button>
                          </>
                         )}

                         {p.verified === "yes" && (
                          <button
                           className="btn btn-danger"
                           onClick={() => updateKYC(p.wallet, "no")}
                          >
                           Remove KYC
                          </button>
                         )}
                         <button
                          type="button"
                          className="btn btn-secondary close-btn"
                          data-dismiss="modal"
                         >
                          Close
                         </button>
                        </div>
                       </div>
                      </div>
                     </div>
                    </>
                   ))}
                 </div>
                )}
                {isLoading && <p>Loading...</p>}
               </div>
               <div className="col-md-2" />
              </div>

              <div className="row">
               {users.length > 0 &&
                users.map((p, i) => (
                 <>
                  <div className="col-lg-6">
                   <div className="card nft-card">
                    <div className="card-body">
                     <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                      <img
                       src={domain + p.profile_photo}
                       className="card-img-topa"
                       alt="Avatar"
                       width={50}
                       style={{ borderRadius: "50%" }}
                      />
                      <div>
                       <h5 className="card-title">
                        <a
                         href={`profile?user=${!p.username ? p.wallet : p.username}`}
                         target="_blank"
                        >
                         {!p.username ? shortenAddress(p.wallet) : p.username}
                        </a>{" "}
                        {p.verified === "yes" && (
                         <i className="fa fa-check-circle text-success" aria-hidden="true" />
                        )}
                       </h5>

                       <p className="price">
                        {formatNumber(p.credits)} v18plus{" "}
                        <span className="text-primary">
                         ($
                         {formatNumber(Math.round(tokenPrice * p.credits))})
                        </span>
                       </p>
                       <p>KYC: {p.verified}</p>
                       <p>Creator: {p.is_creator}</p>
                       <div
                        className="text-center mt-1 d-flex"
                        style={{
                         gap: 20,
                         justifyContent: "center",
                        }}
                       >
                        <a
                         href={`profile?user=${!p.username ? p.wallet : p.username}`}
                         target="_blank"
                        >
                         <i class="fa fa-eye text-success" aria-hidden="true"></i>
                        </a>
                        <button data-toggle="modal" data-target={`#editModal-${i}`}>
                         <i class="fa fa-pencil-square-o text-primary" aria-hidden="true"></i>
                        </button>
                        <button onClick={() => handleDelete(p.wallet)}>
                         <i class="fa fa-trash-o text-danger" aria-hidden="true"></i>
                        </button>
                       </div>
                      </div>
                     </div>
                    </div>
                   </div>
                  </div>
                  {/*EDIT modal*/}
                  <div
                   className="modal fade"
                   id={`editModal-${i}`}
                   tabIndex={-1}
                   role="dialog"
                   aria-labelledby={`sModalLabel-${i}`}
                   aria-hidden="true"
                  >
                   <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                     <div className="modal-header">
                      <h3 className="modal-title" id={`sModalLabel-${i}`}>
                       Edit {!p.username ? shortenAddress(p.wallet) : p.username}
                      </h3>
                      <button
                       type="button"
                       className="close"
                       data-dismiss="modal"
                       aria-label="Close"
                      >
                       <span aria-hidden="true">×</span>
                      </button>
                     </div>
                     <div className="modal-body">
                      {p.verified !== "no" && (
                       <div className="row">
                        <div className="col-md-12">ID DOCUMENTS</div>
                        <div className="col-md-3">
                         <img src={domain + p.front} height={150} />
                         <p>Front</p>
                        </div>
                        <div className="col-md-3">
                         <img src={domain + p.back} height={150} />
                         <p>Back</p>
                        </div>{" "}
                        <div className="col-md-3">
                         <img src={domain + p.self} height={150} />
                         <p>Self</p>
                        </div>
                        <div className="col-md-6">
                         <div className="form-group">
                          <label htmlFor="username">First name</label>
                          <input
                           type="text"
                           className="form-control"
                           value={p.first_name || ""}
                           readOnly
                          />
                         </div>
                        </div>
                        <div className="col-md-6">
                         <div className="form-group">
                          <label htmlFor="username">Last name</label>
                          <input
                           type="text"
                           className="form-control"
                           value={p.surname || ""}
                           readOnly
                          />
                         </div>
                        </div>
                        <div className="col-md-6">
                         <div className="form-group">
                          <label>Gender</label>
                          <input
                           type="text"
                           className="form-control"
                           value={p.gender || ""}
                           readOnly
                          />
                         </div>
                        </div>
                        <div className="col-md-6">
                         <div className="form-group">
                          <label>Date of birth</label>
                          <input
                           type="text"
                           className="form-control"
                           value={p.dob || ""}
                           readOnly
                          />
                         </div>
                        </div>
                        <div className="col-md-6">
                         <div className="form-group">
                          <label>Country</label>
                          <input
                           type="text"
                           className="form-control"
                           value={p.country || ""}
                           readOnly
                          />
                         </div>
                        </div>
                        <div className="col-md-6">
                         <div className="form-group">
                          <label>ID type</label>
                          <input
                           type="text"
                           className="form-control"
                           value={p.id_type || ""}
                           readOnly
                          />
                         </div>
                        </div>
                        <div className="col-md-6">
                         <div className="form-group">
                          <label>ID NUMBER</label>
                          <input
                           type="text"
                           className="form-control"
                           value={p.id_number || ""}
                           readOnly
                          />
                         </div>
                        </div>
                       </div>
                      )}
                      <div className="row">
                       <div className="col-md-6">
                        <div className="form-group">
                         <label htmlFor="username">Wallet</label>
                         <input
                          type="text"
                          className="form-control"
                          value={p.wallet || ""}
                          readOnly
                         />
                        </div>
                       </div>
                       <div className="col-md-6">
                        <div className="form-group">
                         <label htmlFor="username">Email</label>
                         <input
                          type="text"
                          className="form-control"
                          value={p.email || ""}
                          readOnly
                         />
                        </div>
                       </div>
                      </div>
                     </div>

                     <div className="modal-footer">
                      {p.verified === "pending" && (
                       <>
                        <button
                         type="button"
                         className="btn btn-success"
                         onClick={() => updateKYC(p.wallet, "yes")}
                        >
                         Approve KYC
                        </button>
                        <button
                         type="button"
                         className="btn btn-danger"
                         onClick={() => updateKYC(p.wallet, "no")}
                        >
                         Reject KYC
                        </button>
                       </>
                      )}

                      {p.verified === "yes" && (
                       <button className="btn btn-danger" onClick={() => updateKYC(p.wallet, "no")}>
                        Remove KYC
                       </button>
                      )}
                      <button
                       type="button"
                       className="btn btn-secondary close-btn"
                       data-dismiss="modal"
                      >
                       Close
                      </button>
                     </div>
                    </div>
                   </div>
                  </div>
                 </>
                ))}
              </div>
              {users.length > 0 && (
               <Pagination
                currentPage={paginationU.current_page}
                totalPages={paginationU.total_pages}
                onPageChange={(page) => getUsers(page)}
               />
              )}
             </>
            )}

            {menu === "videos" && (
             <>
              <div className="row">
               <div className="col-md-2" />
               <div className="col-md-8">
                <input
                 placeholder="Search videos by creators wallet or title"
                 className="form-control my-5"
                 style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  width: "100%",
                  height: "60px",
                  border: "#aaa 1px solid",
                 }}
                 type="search"
                 value={vquery}
                 onChange={(e) => setVQuery(e.target.value)}
                />
                {!isLoading && (
                 <div>
                  {vresults.length > 0 &&
                   vresults.map((v, i) => (
                    <>
                     <div className="card nft-card">
                      <div className="card-body">
                       <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                        <img
                         src={domain + v.cover_photo}
                         className="card-img-topa"
                         alt="Avatar"
                         width={100}
                        />
                        <div>
                         <h5 className="card-title">{v.title}</h5>

                         <p className="price">{v.views} views</p>

                         <div
                          className="text-center mt-1 d-flex"
                          style={{
                           gap: 20,
                           justifyContent: "center",
                          }}
                         >
                          <button data-toggle="modal" data-target={`#SvModal-${i}`}>
                           <i class="fa fa-eye text-success" aria-hidden="true"></i>
                          </button>

                          <button onClick={() => doDelete(v.id)}>
                           <i class="fa fa-trash-o text-danger" aria-hidden="true"></i>
                          </button>
                         </div>
                        </div>
                       </div>
                      </div>
                     </div>
                     {/*WATCH modal*/}
                     <div
                      className="modal fade"
                      id={`SvModal-${i}`}
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby={`vModalLabel-${i}`}
                      aria-hidden="true"
                     >
                      <div className="modal-dialog modal-lg" role="document">
                       <div className="modal-content">
                        <div className="modal-header">
                         <h3 className="modal-title" id={`vModalLabel-${i}`}>
                          Watching Video by{" "}
                          <a
                           href={`profile?user=${!v.username ? v.wallet : v.username}`}
                           target="_blank"
                           className="text-warning"
                          >
                           {!v.username ? shortenAddress(v.wallet) : v.username}
                          </a>{" "}
                          {v.verified === "yes" && (
                           <i className="fa fa-check-circle text-success" aria-hidden="true" />
                          )}
                         </h3>
                         <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                         >
                          <span aria-hidden="true">×</span>
                         </button>
                        </div>
                        <div className="modal-body">
                         {v.title}
                         {v.video && (
                          <video
                           controls
                           width="100%"
                           // height="660px"

                           onError={(e) => console.error("Error loading video:", e)}
                           className="mt-3"
                          >
                           <source
                            src={
                             typeof v.video === "string" && v.video.startsWith("http")
                              ? v.video
                              : domain + v.video
                            }
                            type="video/mp4"
                           />
                           Your browser does not support the video tag.
                          </video>
                         )}
                        </div>

                        <div className="modal-footer">
                         <button
                          type="button"
                          className="btn btn-danger close-btn"
                          onClick={() => doDelete(v.id)}
                         >
                          DELETE
                         </button>
                         <button
                          type="button"
                          className="btn btn-secondary close-btn"
                          data-dismiss="modal"
                         >
                          Close
                         </button>
                        </div>
                       </div>
                      </div>
                     </div>
                    </>
                   ))}
                 </div>
                )}
                {isLoading && <p>Loading...</p>}
               </div>
               <div className="col-md-2" />
              </div>

              <div className="row">
               {videos.length > 0 &&
                videos.map((v, i) => (
                 <>
                  <div className="col-lg-4 mb-4">
                   <div className="item-slick">
                    <div className="post-item">
                     <div className="pic" style={{ position: "relative" }}>
                      <img src={domain + v.cover_photo} alt="IMG" className="vr-slider" />

                      <div
                       className="overlay"
                       style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        zIndex: 2,
                       }}
                       data-toggle="modal"
                       data-target={`#vModal-${i}`}
                      />
                      <div
                       className="meta-info"
                       style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        color: "#fff",
                        zIndex: 2,
                       }}
                      >
                       <div className="imdb">
                        <span className="value">{v.views}</span> VIEWS
                       </div>
                      </div>
                      <div
                       style={{
                        position: "absolute",
                        top: "50%",
                        left: "0%",
                        transform: "translate(-0%, -50%)",
                        color: "#fff",
                        textAlign: "center",
                        padding: "0 10px",
                        zIndex: 2,
                        cursor: "pointer",
                       }}
                       data-toggle="modal"
                       data-target={`#vModal-${i}`}
                      >
                       <h4 className="title">{v.title}</h4>
                      </div>
                      <div
                       className="label"
                       style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        background: "#e40914",
                        padding: "5px 10px",
                        color: "#fff",
                        borderRadius: "5px",
                        zIndex: 2,
                       }}
                      >
                       {v.category}
                      </div>
                     </div>
                    </div>
                   </div>
                  </div>
                  {/*WATCH modal*/}
                  <div
                   className="modal fade"
                   id={`vModal-${i}`}
                   tabIndex={-1}
                   role="dialog"
                   aria-labelledby={`vModalLabel-${i}`}
                   aria-hidden="true"
                  >
                   <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                     <div className="modal-header">
                      <h3 className="modal-title" id={`vModalLabel-${i}`}>
                       Watching Video by{" "}
                       <a
                        href={`profile?user=${!v.username ? v.wallet : v.username}`}
                        target="_blank"
                        className="text-warning"
                       >
                        {!v.username ? shortenAddress(v.wallet) : v.username}
                       </a>{" "}
                       {v.verified === "yes" && (
                        <i className="fa fa-check-circle text-success" aria-hidden="true" />
                       )}
                      </h3>
                      <button
                       type="button"
                       className="close"
                       data-dismiss="modal"
                       aria-label="Close"
                      >
                       <span aria-hidden="true">×</span>
                      </button>
                     </div>
                     <div className="modal-body">
                      {v.title}
                      {v.video && (
                       <video
                        controls
                        width="100%"
                        // height="660px"

                        onError={(e) => console.error("Error loading video:", e)}
                        className="mt-3"
                       >
                        <source
                         src={
                          typeof v.video === "string" && v.video.startsWith("http")
                           ? v.video
                           : domain + v.video
                         }
                         type="video/mp4"
                        />
                        Your browser does not support the video tag.
                       </video>
                      )}
                     </div>

                     <div className="modal-footer">
                      <button
                       type="button"
                       className="btn btn-danger close-btn"
                       onClick={() => doDelete(v.id)}
                      >
                       DELETE
                      </button>
                      <button
                       type="button"
                       className="btn btn-secondary close-btn"
                       data-dismiss="modal"
                      >
                       Close
                      </button>
                     </div>
                    </div>
                   </div>
                  </div>
                 </>
                ))}
              </div>
              {videos.length > 0 && (
               <Pagination
                currentPage={paginationV.current_page}
                totalPages={paginationV.total_pages}
                onPageChange={(page) => getVideos(page)}
               />
              )}
             </>
            )}
            {menu === "products" && (
             <div className="container">
              <div className="row">
               <div className="col-md-4">
                <input
                 placeholder="Product title"
                 className="form-control my-5"
                 type="text"
                 value={title}
                 onInput={(e) => setTitle(e.target.value)}
                />
               </div>
               <div className="col-md-4 my-5">
                <select onChange={(e) => setGender(e.target.value)}>
                 <option selected> Select a gender</option>
                 <option value="all">All</option>
                 <option value="female">Female</option>
                 <option value="male">Male</option>
                </select>
               </div>
               <div className="col-md-4">
                <input
                 value={price}
                 onInput={(e) => setPrice(e.target.value)}
                 placeholder="Product price"
                 className="form-control my-5"
                 type="number"
                 min={1}
                />
               </div>
               <div className="col-md-6 my-2">
                <input
                 // value={image}
                 onInput={(e) => setImage(e.target.value)}
                 value={image}
                 placeholder="Product image"
                 className="form-control "
                 type="url"
                />
               </div>
               <div className="col-md-6 my-2">
                <input
                 value={link}
                 onInput={(e) => setLink(e.target.value)}
                 placeholder="Product link"
                 className="form-control"
                 type="url"
                />
               </div>
               <div className="col-md-12 my-4">
                <div className="text-center">
                 <button onClick={postProduct} className="btn btn-custom">
                  Submit
                 </button>
                </div>
               </div>
              </div>

              <div className="row">
               <div className="col-md-2" />
               <div className="col-md-8">
                <input
                 placeholder="Search products by name"
                 className="form-control my-5"
                 style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  width: "100%",
                  height: "60px",
                  border: "#aaa 1px solid",
                 }}
                 type="search"
                 value={pquery}
                 onChange={(e) => setPQuery(e.target.value)}
                />
                {!isLoading && (
                 <ul>
                  {presults.length > 0 &&
                   presults.map((p, index) => (
                    <li key={index} className="my-2 search-hover">
                     <a href={p.link} style={{ color: "#fff" }} target="_blank">
                      <div className="text-left ml-5">
                       <img src={p.image} alt="Product" width="50" className="mr-2" />
                       {p.title}
                      </div>
                     </a>
                     <div
                      className="text-center mt-1 d-flex"
                      style={{
                       gap: 20,
                       justifyContent: "center",
                      }}
                     >
                      <a href={p.link} target="_blank">
                       <i class="fa fa-eye text-success" aria-hidden="true"></i>
                      </a>

                      <button onClick={() => deleteProduct(p.id)}>
                       <i class="fa fa-trash-o text-danger" aria-hidden="true"></i>
                      </button>
                     </div>
                    </li>
                   ))}
                 </ul>
                )}
                {isLoading && <p>Loading...</p>}
               </div>
               <div className="col-md-2" />
              </div>
              <div className="row mt-4">
               {products.length > 0 ? (
                products.map((p, i) => (
                 <div className="col-md-6" key={i}>
                  <div className="card nft-card">
                   <img src={p.image} className="card-img-top" alt="Preatent" />
                   <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center"></div>
                    <h5 className="card-title">{p.title}</h5>

                    <p className="price">${formatNumber(p.price)}</p>
                    <div
                     className="text-center mt-1 d-flex"
                     style={{
                      gap: 20,
                      justifyContent: "center",
                     }}
                    >
                     <a href={p.link} target="_blank">
                      <i class="fa fa-eye text-success" aria-hidden="true"></i>
                     </a>

                     <button onClick={() => deleteProduct(p.id)}>
                      <i class="fa fa-trash-o text-danger" aria-hidden="true"></i>
                     </button>
                    </div>
                   </div>
                  </div>
                 </div>
                ))
               ) : (
                <div className="text-center">
                 <img src="assets/images/fly.gif" height={100} />
                 <p>No products here yet...</p>
                </div>
               )}

               {/* Repeat for other cards */}
              </div>
              {products.length > 0 && (
               <Pagination
                currentPage={paginationP.current_page}
                totalPages={paginationP.total_pages}
                onPageChange={(page) => getProducts(page)}
               />
              )}
             </div>
            )}
            {menu === "settings" && (
             <form onSubmit={submitSettings}>
              <div className="row">
               <div className="col-md-6">
                <div className="form-group">
                 <label>Admin Email:</label>
                 <input
                  className="form-control"
                  type="email"
                  name="admin_email"
                  value={setting.admin_email}
                  onInput={changeSettings}
                 />
                </div>
               </div>

               <div className="col-md-6">
                <div className="form-group">
                 <label>Project ID:</label>
                 <input
                  className="form-control"
                  type="text"
                  name="project_id"
                  value={setting.project_id}
                  onInput={changeSettings}
                 />
                </div>
               </div>
               <div className="col-md-6">
                <div className="form-group">
                 <label>Porn Face Fee:</label>
                 <input
                  className="form-control"
                  type="number"
                  name="porn_face_fee"
                  value={setting.porn_face_fee}
                  onInput={changeSettings}
                 />
                </div>
               </div>
               <div className="col-md-6">
                <div className="form-group">
                 <label>Swap Face Fee:</label>
                 <input
                  className="form-control"
                  type="number"
                  name="swap_face_fee"
                  value={setting.swap_face_fee}
                  onInput={changeSettings}
                 />
                </div>
               </div>

               <div className="col-md-6">
                <div className="form-group">
                 <label>Token:</label>
                 <input
                  className="form-control"
                  type="text"
                  name="token"
                  value={setting.token}
                  onInput={changeSettings}
                 />
                </div>
               </div>

               <div className="col-md-6">
                <div className="form-group">
                 <label>Token Decimal:</label>
                 <input
                  className="form-control"
                  type="number"
                  name="token_decimal"
                  value={setting.token_decimal}
                  onInput={changeSettings}
                 />
                </div>
               </div>

               <div className="col-md-6">
                <div className="form-group">
                 <label>Current Daily Mint:</label>
                 <input
                  className="form-control"
                  type="number"
                  name="current_daily_mint"
                  value={setting.current_daily_mint}
                  onInput={changeSettings}
                 />
                </div>
               </div>

               <div className="col-md-12 text-center mt-4">
                <button className="btn btn-custom" type="submit">
                 Update Settings
                </button>
               </div>
              </div>
             </form>
            )}
           </div>
          </div>
         </div>
        </div>
       </div>
      )}
      {!isConnected && (
       <div className="alert-warning p-3 my-3 rounded">
        <i className="fa fa-info-circle" aria-hidden="true"></i> Please connect your wallet to use
        this app.
       </div>
      )}
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default Admin;
